import Board from "../Common/Board";
import React from "react";
import ScoreModel from "../../Models/ScoreModel";

class Nevco extends React.Component {
  constructor(props) {
    super(props);
    this.scoreObj = props.scoreObj;
    this.state = {
      intervalId: {},
      score: props.scoreObj,
    };
    this.boardRef = {};
    this.interval = 300;
    // this.serverIP = "localhost:3000"; //"94.176.235.224:3000"; //"localhost:3000"; //"94.176.235.224:3000";
  }
  componentDidMount() {
    this.props.biRef.startAction = this.startAction;
    this.props.biRef.stopAction = this.stopAction;
  }
  _start = () => {
    this.scoreObj.init();
    var intervalId = setInterval(() => {
      fetch(this.props.url, {
        method: "get",
        headers: { "Content-Type": "text/plain" },
        mode: "cors",
      })
        .then((response) => {
          if (response.ok) return response.json();
          else throw Error(response.status);
        })
        .then((jsonData) => {
          // console.log(jsonData);
          var flags = [
            this.scoreObj.IsHalf,
            this.scoreObj.IsFinal,
            this.scoreObj.IsELAM,
          ];
          this.scoreObj.formatNEVCOData(jsonData);
          var setFlags = [
            this.scoreObj.IsHalf,
            this.scoreObj.IsFinal,
            this.scoreObj.IsELAM,
          ];
          if (!flags.every((val, i) => val == setFlags[i]))
            this.props.setFlags(
              this.scoreObj.IsHalf,
              this.scoreObj.IsFinal,
              this.scoreObj.IsELAM
            );

          this.setState({ score: this.scoreObj });
          this.props.setScoreObj(this.scoreObj);
        })
        .catch((err) => {
          console.log(err);
        });
    }, this.interval);
    this.setState({ intervalId: intervalId });
  };
  startAction = () => {
    this._start();
    this.boardRef.startTransmitting();
  };
  stopAction = () => {
    console.log(this.state.intervalId);
    clearInterval(this.state.intervalId);
    this.boardRef.stopTransmitting();
  };

  render() {
    return (
      <Board
        ComputerID={this.props.ComputerID}
        biRef={this.boardRef}
        score={this.state.score}
        selectedLanguage={this.props.selectedLanguage}
        setIsConnected={(status) => this.props.setIsConnected(status)}
        setIsReceived={(status) => this.props.setIsReceived(status)}
        setIsTransmitting={(status) => {
          if (status) {
            this._start();
          } else {
            clearInterval(this.state.intervalId);
          }
          this.props.setIsTransmitting(status);
        }}
        setConfig={(config) => this.props.setConfig(config)}
      />
    );
  }
}

export default Nevco;
