import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import { Button, Card, IconButton, Stack, TextField } from "@mui/material";
import { green, lightGreen, lime, red } from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import CallMadeIcon from "@mui/icons-material/CallMade";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import StopCircleOutlinedIcon from "@mui/icons-material/StopCircleOutlined";

function IDElement(props) {
  function stopAction() {
    fetch("/api/stopAction?id=" + props.data.ID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((jsonData) => {
        console.log(jsonData);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function startAction() {
    fetch("/api/startAction?id=" + props.data.ID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((jsonData) => {
        console.log(jsonData);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <Card style={{ backgroundColor: "#f2f2f2" }}>
      <div
        style={{
          display: "grid",
          // backgroundColor: "#21325b",
          gridTemplateColumns: "50px 1fr auto",
        }}
      >
        <div>
          {props.data.IsConnected && (
            <CircleIcon sx={{ color: lightGreen.A700, margin: "5px" }} />
          )}
          {!props.data.IsConnected && (
            <CircleIcon sx={{ color: red[300], margin: "5px" }} />
          )}
        </div>
        <div
          style={{
            display: "grid",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <span>{props.data.ID}</span>
          <span style={{ fontWeight: "bold" }}>{props.data.Venue}</span>
        </div>
        <div style={{ display: "grid", justifyItems: "center" }}>
          <Stack
            style={{ justifyContent: "center", padding: "3px" }}
            direction="row"
          >
            {props.data.IsTransmitting && <strong>Transmitting</strong>}
            {props.data.IsReceiving && <strong>Receiving</strong>}
          </Stack>

          {(props.data.IsTransmitting || props.data.IsReceiving) && (
            <IconButton
              onClick={() => stopAction()}
              color="primary"
              size="large"
            >
              <StopCircleOutlinedIcon
                style={{ width: "40px", height: "40px" }}
              />
            </IconButton>
          )}
          {!props.data.IsTransmitting && !props.data.IsReceiving && (
            <IconButton
              onClick={() => startAction()}
              color="primary"
              size="large"
            >
              <PlayCircleOutlineIcon
                style={{ width: "40px", height: "40px" }}
              />
            </IconButton>
          )}
        </div>
      </div>
    </Card>
  );
}

export default class MasterControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ShowStatus: false,
      IDStatus: true,
      IDList: [],
      NewID: "",
      intervalID: null,
    };
    this.interval = 1000;
  }

  loadIDList = () => {
    fetch("/api/loadIDList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((jsonData) => {
        //console.log(jsonData);
        var connectedList = JSON.parse(jsonData).filter(
          (item) => item.IsConnected
        );
        //console.log(connectedList);
        this.setState({ IDList: connectedList });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  createID = () => {
    fetch("/api/createID?id=" + this.state.NewID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        this.setState({
          IDStatus: jsonData["status"],
          ShowStatus: true,
        });
        if (jsonData["status"]) this.loadIDList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount() {
    this.loadIDList();

    var intervalID = setInterval(() => {
      this.loadIDList();
    }, this.interval);

    this.setState({ intervalID: intervalID });
  }

  componentWillUnmount() {
    if (this.state.intervalID) {
      clearInterval(this.state.intervalID);
    }
  }

  render() {
    return (
      <div style={{ display: "grid", gridTemplateRows: "auto 1fr" }}>
        {/* <Card
          style={{
            margin: "5px",
            padding: "10px",
            backgroundColor: "#f2f2f2",
          }}
        >
          <Stack
            direction="row"
            style={{
              alignItems: "center",
              display: "grid",
              gridTemplateColumns: "1fr auto 1fr",
              justifyContent: "center",
            }}
          >
            <TextField
              color="primary"
              sx={{ width: 200 }}
              style={{ justifySelf: "flex-end" }}
              value={this.state.NewID}
              onChange={(e) => {
                this.setState({ NewID: e.target.value, ShowStatus: false });
              }}
              variant="standard"
              label="ID"
            />

            <Button
              onClick={() => {
                this.createID();
              }}
              style={{ marginLeft: "10px" }}
              variant="contained"
            >
              CREATE ID
            </Button>

            {this.state.ShowStatus && this.state.IDStatus && (
              <span style={{ color: "green", marginLeft: "20px" }}>
                !! ID {this.state.NewID} Creation Successful !!
              </span>
            )}
            {this.state.ShowStatus && !this.state.IDStatus && (
              <span style={{ color: "red", marginLeft: "20px" }}>
                !! ID {this.state.NewID} Already Exists !!
              </span>
            )}
          </Stack>
        </Card> */}
        <div>
          <div
            style={{
              margin: "5px",
              padding: "10px",
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "10px",
            }}
          >
            {this.state.IDList.map((field, i) => (
              <IDElement key={i} data={field} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
