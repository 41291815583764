import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "./Manual.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import React, { useEffect, useState } from "react";
import ScoreModel from "../../Models/ScoreModel";
import SERVER from "../Common/Constants";
import localizedStrings from "../../localization/localizedStrings";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Cookies from "js-cookie";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import classes from "./s.css";

const ReactDomServer = require("react-dom/server");

class Manual extends React.Component {
  constructor(props) {
    super(props);
    localizedStrings.setLanguage(props.config.selectedLanguage);
    this.scoreObj = props.scoreObj; //new ScoreModel(props.ComputerID, props.config);
    this.state = {
      score: props.scoreObj,
      intervalId: {},
      startBothClocks: false,
      mainClock: false,
      mainClockKey: false,
      shotClock: false,
      shotClockKey: false,
      mainTime: "FINAL",
      shotTime: "24",
      mainClockProgressValue: 0,
    };
    this.time = "";
    this.shottime = "";
    this.interval = 300;
    this.periodList = ["1", "2", "3", "4"];
    console.log(this.periodList);
    // this.serverIP = "localhost:3000"; //"94.176.235.224:3000"; //"localhost:3000"; //"94.176.235.224:3000";
    // console.log(props.scoreObj.Period);
    this.isTransmitting = false;
    this.isReceiving = props.isReceiving;
    this.socketInstance = null;
  }
  createConnection = (ID) => {
    this.socketInstance = new W3CWebSocket(SERVER.url);
    this.socketInstance.onopen = () => {
      console.log("[open] Connection established ");
      var venue = Cookies.get("VenueID");
      this.props.setIsConnected(true);
      this.socketInstance.send(
        JSON.stringify({
          type: "ID",
          data: { venue: venue },
          id: ID,
        })
      );
      this.startTransmitting();
      this.props.setIsTransmitting(true);
      // if (next) next();
      // this.socketInstance.send(
      //   JSON.stringify({
      //     type: "status",
      //     data: { IsTransmitting: true },
      //     id: ID,
      //   })
      // );
    };

    this.socketInstance.onmessage = (ev) => {
      var message = JSON.parse(ev.data);
      if (message["type"] == "action") {
        console.log(message);
        if (message["data"] == "start" && !this.isTransmitting) {
          this.startTransmitting();
          this.props.setIsTransmitting(true);
        } else if (message["data"] == "stop" && this.isTransmitting) {
          this.stopTransmitting();
          this.props.setIsTransmitting(false);
        } else if (message["data"] == "config") {
          this.props.setConfig(message["config"]);
        }
      }
    };
    this.socketInstance.onclose = (ev) => {
      this.props.setIsConnected(false);
      console.log("socket closed");
    };

    this.socketInstance.onerror = (error) => {
      this.socketInstance.close();
    };
  };

  getPeriodOrdinal = (period) => {
    if (period == "1") return localizedStrings._1st;
    else if (period == "2") return localizedStrings._2nd;
    else if (period == "3") return localizedStrings._3rd;
    else if (period == "4") return localizedStrings._4th;
  };
  initialLoad = (computerID) => {
    fetch("/api/getjson/?id=" + computerID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((receivedScore) => {
        console.log(receivedScore);
        this.scoreObj.Copy(receivedScore);
        this.scoreObj.RawString = "MANUAL";

        this.props.setResult(this.scoreObj);
        this.setState({ score: this.scoreObj });
      })
      .catch((err) => {
        this.props.setResult(this.scoreObj);
        this.setState({ score: this.scoreObj });

        console.log(err);
      });
  };
  componentDidMount() {
    var ID = Cookies.get("ComputerID");
    if (ID) this.initialLoad(ID);

    this.props.biRef.startTransmitting = this.startTransmitting;
    this.props.biRef.stopTransmitting = this.stopTransmitting;

    if (ID) {
      this.createConnection(ID);
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.mainSecIntervalId);
    clearInterval(this.state.mainMilliSecIntervalId);
    clearInterval(this.state.shotClockInterId);
    if (this.socketInstance && this.socketInstance.readyState == 1) {
      this.socketInstance.close();
    }
    this.isTransmitting = false;
  }
  transmitData = () => {
    var d1 = Date.now();
    if (this.socketInstance.readyState == 1)
      this.socketInstance.send(
        JSON.stringify({
          type: "score",
          data: this.scoreObj.getData(),
          id: this.props.ComputerID,
          venue: this.props.config.Venue,
        })
      );
    var d2 = Date.now();
    // console.log(d2 - d1);

    this.scoreObj.checkForELAM();

    this.scoreObj.checkForFinal(this.scoreObj.Time);

    this.scoreObj.checkForHalf(this.scoreObj.Time);

    this.props.setResult(this.scoreObj.getData());

    this.setState({
      score: this.scoreObj,
    });
    this.props.setScoreObj(this.scoreObj);

    if (this.scoreObj && this.isTransmitting) {
      var interval = this.interval;
      if (this.scoreObj.Time.includes(".")) interval = 100;
      this.delay(interval).then(() => this.transmitData());
    }
  };
  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  startTransmitting = () => {
    var ID = Cookies.get("ComputerID");
    this.isTransmitting = true;
    if (!this.socketInstance) {
      this.createConnection();
    } else {
      this.socketInstance.send(
        JSON.stringify({
          type: "status",
          data: { IsTransmitting: true },
          id: ID,
        })
      );
      this.transmitData();
    }
  };
  stopTransmitting = () => {
    var ID = Cookies.get("ComputerID");
    this.socketInstance.send(
      JSON.stringify({
        type: "status",
        data: { IsTransmitting: false },
        id: ID,
      })
    );
    this.isTransmitting = false;
  };

  changeHomeScore = (step) => {
    if (!isNaN(parseInt(this.scoreObj.HomeScore)))
      this.scoreObj.HomeScore = parseInt(this.scoreObj.HomeScore) + step;
    else this.scoreObj.HomeScore = step;
    this.setState({
      score: this.scoreObj,
    });
  };

  changeAwayScore = (step) => {
    if (!isNaN(parseInt(this.scoreObj.AwayScore)))
      this.scoreObj.AwayScore = parseInt(this.scoreObj.AwayScore) + step;
    else this.scoreObj.AwayScore = step;
    this.setState({
      score: this.scoreObj,
    });
  };

  changeHomeTOL = (step) => {
    if (!isNaN(parseInt(this.scoreObj.HomeTOL)))
      this.scoreObj.HomeTOL = parseInt(this.scoreObj.HomeTOL) + step;
    else this.scoreObj.HomeTOL = step;
    this.setState({
      score: this.scoreObj,
    });
  };
  changeAwayTOL = (step) => {
    if (!isNaN(parseInt(this.scoreObj.AwayTOL)))
      this.scoreObj.AwayTOL = parseInt(this.scoreObj.AwayTOL) + step;
    else this.scoreObj.AwayTOL = step;
    this.setState({
      score: this.scoreObj,
    });
  };

  changeHomeFouls = (step) => {
    if (!isNaN(parseInt(this.scoreObj.HomeFouls)))
      this.scoreObj.HomeFouls = parseInt(this.scoreObj.HomeFouls) + step;
    else this.scoreObj.HomeFouls = step;
    this.setState({
      score: this.scoreObj,
    });
  };
  changeAwayFouls = (step) => {
    if (!isNaN(parseInt(this.scoreObj.AwayFouls)))
      this.scoreObj.AwayFouls = parseInt(this.scoreObj.AwayFouls) + step;
    else this.scoreObj.AwayFouls = step;
    this.setState({
      score: this.scoreObj,
    });
  };
  startMilliSecClock = () => {
    clearInterval(this.state.mainSecIntervalId);
    this.setState({ mainSecIntervalId: null });
    var mainMilliSecIntervalId = setInterval(() => {
      var secFloat = parseFloat(this.state.score.Time);
      if (secFloat >= 0.1) {
        secFloat = secFloat - 0.1;

        this.setState({ mainClockProgressValue: 1.0 - secFloat / 600 });
        this.scoreObj.Time = this.formatTime(secFloat.toFixed(1).toString());
        this.setState({
          score: this.scoreObj,
        });
      } else {
        this.setState({ mainClockProgressValue: 1.0 });
        clearInterval(this.state.mainMilliSecIntervalId);
        this.setState({ mainMilliSecIntervalId: null });
      }
    }, 100);
    this.setState({ mainMilliSecIntervalId: mainMilliSecIntervalId });
  };
  mainClockCallback = () => {
    var mainSecIntervalId = setInterval(() => {
      var time = this.state.score.Time;
      var formattedTime = "";
      if (time.includes(":")) {
        var lines = time.split(":");
        var Min = parseInt(lines[0]);
        var Sec = parseInt(lines[1]);

        if (Sec == 0) {
          Min = Min - 1;
          Sec = 59;
        } else Sec = Sec - 1;

        if (Min > 0)
          formattedTime =
            Min.toString() + ":" + Sec.toString().padStart(2, "0");
        else formattedTime = Sec.toString().padStart(2, "0");

        if (Min == 0) {
          this.startMilliSecClock();
        }
        this.setState({ mainClockProgressValue: 1.0 - (Min * 60 + Sec) / 600 });
        this.scoreObj.Time = formattedTime;
        this.setState({
          score: this.scoreObj,
        });
      } else {
        this.startMilliSecClock();
      }
    }, 1000);
    this.setState({ mainSecIntervalId: mainSecIntervalId });
  };

  startShotClock = () => {
    var shotClockInterId = setInterval(() => {
      var shotClockInt = parseInt(this.state.score.ShotClock);
      // console.log(shotClockInt);
      if (!isNaN(shotClockInt)) {
        if (shotClockInt > 0) {
          this.scoreObj.ShotClock = shotClockInt - 1;
          this.setState({
            score: this.scoreObj,
          });
        } else {
          clearInterval(this.state.shotClockInterId);
          this.setState({ shotClockInterId: null });
        }
      } else {
        clearInterval(this.state.shotClockInterId);
        this.setState({ shotClockInterId: null });
      }
    }, 1000);

    this.setState({ shotClockInterId: shotClockInterId });
  };

  formatTime = (time) => {
    if (
      time == "0.0" ||
      time == "0" ||
      time == "0:00" ||
      time == "0:0" ||
      time == "00:00" ||
      time == "0.0" ||
      time == "0.00" ||
      time == "00.0" ||
      time == ".0"
    )
      return "";
    else return time;
  };
  renderTime = ({ remainingTime }) => {
    console.log(remainingTime);
    const mins = Math.floor(remainingTime / 60);
    const secs = remainingTime % 60;
    this.time = mins + ":" + secs;
    if (this.state.mainClock) {
      return (
        <Typography
          sx={{
            color: "white",
            fontSize: "24px",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          {mins}:{secs}
        </Typography>
      );
    }
    const styles = (theme) => ({
      notchedOutline: {
        borderWidth: "1px",
        borderColor: "yellow !important",
      },
    });

    return (
      <TextField
        value={this.state.mainTime}
        onChange={(e) => this.setState({ mainTime: e.target.value })}
        variant="standard"
        sx={{
          input: {
            color: "white",
            fontSize: "24px",
            width: "75px",
            fontWeight: "bold",
            textAlign: "center",
          },
        }}
      />
    );
  };
  renderShotTime = ({ remainingTime }) => {
    // this.shottime = remainingTime;
    // if (this.state.shotClock) {
    //   return (
    //     <Typography
    //       sx={{
    //         color: "white",
    //         fontSize: "24px",
    //         fontWeight: "bold",
    //         textAlign: "center",
    //       }}
    //     >
    //       {remainingTime}
    //     </Typography>
    //   );
    // }

    return (
      <TextField
        value={this.state.score.ShotClock}
        onChange={(e) => {
          this.scoreObj.ShotClock = e.target.value;
          this.setState({ score: this.scoreObj });
        }}
        variant="standard"
        sx={{
          input: {
            color: "white",
            fontSize: "24px",
            width: "75px",
            fontWeight: "bold",
            textAlign: "center",
          },
        }}
      />
    );
  };
  render() {
    return (
      <Stack
        style={{ justifyContent: "space-evenly" }}
        direction="row"
        className=""
        spacing={2}
      >
        <Stack direction="column" className="manualGrid">
          <Stack className="mainClockGap">
            {(!this.state.score.IsELAM || this.state.score.IsFinal) && (
              <Stack spacing={2}>
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    paddingLeft: "1rem",
                  }}
                >
                  Game Clock
                </Typography>
                <Stack
                  className="horizontalPanel"
                  alignItems="center"
                  style={{
                    margin: "1rem 0 0.5rem 0",
                    height: 170,
                  }}
                >
                  <div
                    style={{ width: 200, height: 200, position: "absolute" }}
                  >
                    <CircularProgressbar
                      maxValue={1}
                      value={this.state.mainClockProgressValue}
                      strokeWidth={4}
                      styles={{
                        root: {},
                        path: {
                          stroke: `rgba(62, 152, 199, ${
                            this.state.mainClockProgressValue * 255
                          })`,
                          strokeLinecap: "butt",
                          transition: "stroke-dashoffset 0.5s ease 0s",
                        },
                        trail: {
                          stroke: "#d6d6d6",
                          strokeLinecap: "butt",
                        },
                      }}
                    />
                  </div>

                  <TextField
                    variant="standard"
                    value={this.state.score.Time}
                    onChange={(e) => {
                      this.scoreObj.Time = e.target.value;
                      this.setState({ score: this.scoreObj });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "55px",
                        width: "140px",
                        fontWeight: "bold",
                        textAlign: "center",
                        borderColor: "red",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            )}

            {this.state.score.IsELAM && !this.state.score.IsFinal && (
              <Stack
                alignItems="center"
                style={{
                  margin: "1rem 0 0.5rem 0",
                  height: 170,
                }}
                spacing={2}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "bold",
                    paddingLeft: "1rem",
                  }}
                >
                  {localizedStrings.tgt}
                </Typography>
                <div className="horizontalPanel">
                  <TextField
                    variant="standard"
                    value={this.state.score.TargetScore}
                    onChange={(e) => {
                      this.scoreObj.TargetScore = e.target.value;
                      this.setState({ score: this.scoreObj });
                    }}
                    sx={{
                      input: {
                        color: "White",
                        fontSize: "45px",
                        width: "225px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </div>
              </Stack>
            )}

            <Stack direction="row" spacing={2} justifyContent="center">
              <Button
                onClick={() => {
                  this.mainClockCallback();
                  if (
                    this.state.startBothClocks &&
                    !(this.state.shotClockInterId > 0)
                  )
                    this.startShotClock();
                }}
                disabled={
                  this.state.mainSecIntervalId > 0 ||
                  this.state.mainMilliSecIntervalId > 0
                }
                className="rect_button"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
              >
                <PlayArrowIcon />
                {/* {this.state.mainClock ?
                <PlayArrowIcon sx={{color:"black"}}/> : <PlayArrowIcon sx={{color:"white"}}/>
              } */}
              </Button>
              <Button
                onClick={() => {
                  clearInterval(this.state.mainSecIntervalId);
                  clearInterval(this.state.mainMilliSecIntervalId);
                  this.setState({ mainSecIntervalId: null });
                  this.setState({ mainMilliSecIntervalId: null });
                }}
                className="rect_button"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
              >
                <PauseIcon />
              </Button>
              <Button
                onClick={() => {
                  this.scoreObj.Time = "10:00";
                  this.setState({
                    score: this.scoreObj,
                  });
                }}
                className="rect_button"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
              >
                <RefreshIcon />
              </Button>
            </Stack>

            <div style={{ paddingLeft: "2.2rem" }}>
              <FormControlLabel
                label="Sync with Shot Clock"
                sx={{ color: "white" }}
                control={
                  <Checkbox
                    onChange={(e) =>
                      this.setState({ startBothClocks: e.target.checked })
                    }
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 24 },
                      color: "rgba(255,255,255,255)",
                    }}
                  />
                }
              />
            </div>
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            className="quarter"
          >
            <Typography
              sx={{ color: "white", fontSize: "22px", fontWeight: "700" }}
            >
              Quarter
            </Typography>
            <FormControl
              variant="standard"
              sx={{ m: 1, width: 100, color: "white" }}
            >
              <Select
                value={this.state.score.Period}
                onChange={(e) => {
                  this.scoreObj.Period = e.target.value;
                  this.setState({ score: this.scoreObj });
                }}
                disableUnderline
                sx={{
                  ".MuiSelect-icon": { color: "white" },
                  fontSize: "22px",
                  fontWeight: "700",
                  color: "white",
                }}
              >
                {this.periodList.map((period, i) => (
                  <MenuItem
                    key={i}
                    sx={{ fontSize: "22px", fontWeight: "700" }}
                    value={period}
                  >
                    {this.getPeriodOrdinal(period)}
                  </MenuItem>
                ))}
                {/* <MenuItem
                  sx={{ fontSize: "30px", fontWeight: "bold" }}
                  value={"1ST"}
                >
                  1ST
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: "30px", fontWeight: "bold" }}
                  value={"2ND"}
                >
                  2ND
                </MenuItem> */}
              </Select>
            </FormControl>
          </Stack>

          <Stack className="mainClockGap">
            <Typography
              sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
            >
              Shot Clock
            </Typography>
            <Stack
              alignItems="center"
              style={{ margin: "1rem 0 1rem 0" }}
              className="horizontalPanel"
            >
              <CountdownCircleTimer
                key={this.state.shotClockKey}
                isPlaying={this.state.shotClock}
                duration={
                  this.state.score.ShotClock
                    ? parseInt(this.state.score.ShotClock)
                    : 0
                }
                colors={["#22ed0c", "#004777", "#A36F00", "#A30000", "#A30000"]}
                colorsTime={[12, 10, 7, 5, 0]}
                strokeWidth={6}
                size={120}
                onComplete={() =>
                  this.setState({ shotTime: 0, shotClock: false })
                }
                style={{
                  fontSize: "33px",
                  color: "#ffffff",
                  fontWeight: 700,
                  fontFamily: "Poppins",
                }}
              >
                {this.renderShotTime}
              </CountdownCircleTimer>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Button
                onClick={() => {
                  this.startShotClock();
                  // this.scoreObj.ShotClock = this.state.shotTime;
                  // this.setState({
                  //   score: this.scoreObj,
                  //   shotClockKey: !this.state.shotClockKey,
                  //   shotClock: true,
                  // });
                }}
                disabled={this.state.shotClockInterId > 0}
                className="rect_button_2"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
              >
                <PlayArrowIcon />
                {/* { this.state.shotClockInterId ? <PlayArrowIcon sx={{color:"black"}}/> : <PlayArrowIcon sx={{color:"white"}}/>} */}
              </Button>
              <Button
                className="rect_button_2"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
                onClick={() => {
                  clearInterval(this.state.shotClockInterId);
                  this.setState({ shotClockInterId: null });

                  // this.setState({ shotTime: this.shottime, shotClock: false });
                }}
              >
                <PauseIcon sx={{ color: "white" }} />
              </Button>
              {/* <IconButton
                onClick={() => {
                  this.scoreObj.ShotClock = 24;
                  this.setState({
                    score: this.scoreObj,
                  });
                }}
                color="primary"
              >
                <ReplayOutlinedIcon
                  style={{ width: "40px", height: "40px" }}
                />
              </IconButton> */}
              <Button
                className="rect_button_2"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
                endIcon={<ReplayOutlinedIcon />}
                onClick={() => {
                  this.scoreObj.ShotClock = 24;
                  this.setState({
                    shotTime: 24,
                    shotClockKey: !this.state.shotClockKey,
                    score: this.scoreObj,
                  });
                }}
              >
                24
              </Button>
              <Button
                className="rect_button_2"
                variant="contained"
                style={{ backgroundColor: "#171f32" }}
                endIcon={<ReplayOutlinedIcon />}
                onClick={() => {
                  this.scoreObj.ShotClock = 14;
                  this.setState({
                    shotTime: 14,
                    shotClockKey: !this.state.shotClockKey,
                    score: this.scoreObj,
                  });
                }}
              >
                14
              </Button>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" className="manualGrid">
          <Typography
            sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
          >
            Home
          </Typography>
          <div className="horizontalPanel">
            <TextField
              value={this.state.score.HomeScore}
              onChange={(e) => {
                this.scoreObj.HomeScore = e.target.value;
                this.setState({ score: this.scoreObj });
              }}
              variant="standard"
              className="home_score"
              sx={{
                input: {
                  color: "white",
                  fontSize: "54px",
                  width: "75px",
                  fontWeight: "bold",
                  textAlign: "center",
                },
              }}
            />
          </div>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeHomeScore(-1)}
            >
              -1
            </Button>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeHomeScore(1)}
            >
              +1
            </Button>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeHomeScore(2)}
            >
              +2
            </Button>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeHomeScore(3)}
            >
              +3
            </Button>
          </Stack>
          <Stack spacing={4}>
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
              >
                TOLS
              </Typography>
              <Stack direction="row" justifyContent="space-around">
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    value={this.state.score.HomeTOL}
                    onChange={(e) => {
                      this.scoreObj.HomeTOL = e.target.value;
                      this.setState({ score: this.scoreObj });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeHomeTOL(-1)}
                  >
                    -1
                  </Button>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeHomeTOL(1)}
                  >
                    +1
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
              >
                FOULS
              </Typography>
              <Stack direction="row" justifyContent="space-around">
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    value={this.state.score.HomeFouls}
                    onChange={(e) => {
                      this.scoreObj.HomeFouls = e.target.value;
                      this.setState({ score: this.scoreObj });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>

                <Stack spacing={2}>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeHomeFouls(-1)}
                  >
                    -1
                  </Button>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeHomeFouls(1)}
                  >
                    +1
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction="column" className="manualGrid">
          <Typography
            sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
          >
            Away
          </Typography>
          <div className="horizontalPanel">
            <TextField
              variant="standard"
              className="home_score"
              value={this.state.score.AwayScore}
              onChange={(e) => {
                this.scoreObj.AwayScore = e.target.value;
                this.setState({ score: this.scoreObj });
              }}
              sx={{
                input: {
                  color: "white",
                  fontSize: "54px",
                  width: "75px",
                  fontWeight: "bold",
                  textAlign: "center",
                },
              }}
            />
          </div>
          <Stack direction="row" justifyContent="center" spacing={1}>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeAwayScore(-1)}
            >
              -1
            </Button>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeAwayScore(1)}
            >
              +1
            </Button>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeAwayScore(2)}
            >
              +2
            </Button>
            <Button
              className="rect_button_2"
              variant="contained"
              style={{ backgroundColor: "#171f32" }}
              onClick={() => this.changeAwayScore(3)}
            >
              +3
            </Button>
          </Stack>
          <Stack spacing={4}>
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
              >
                TOLS
              </Typography>
              <Stack direction="row" justifyContent="space-around">
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    value={this.state.score.AwayTOL}
                    onChange={(e) => {
                      this.scoreObj.AwayTOL = e.target.value;
                      this.setState({ score: this.scoreObj });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeAwayTOL(-1)}
                  >
                    -1
                  </Button>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeAwayTOL(1)}
                  >
                    +1
                  </Button>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography
                sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
              >
                FOULS
              </Typography>
              <Stack direction="row" justifyContent="space-around">
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    value={this.state.score.AwayFouls}
                    onChange={(e) => {
                      this.scoreObj.AwayFouls = e.target.value;
                      this.setState({ score: this.scoreObj });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
                <Stack spacing={2}>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeAwayFouls(-1)}
                  >
                    -1
                  </Button>
                  <Button
                    className="tols_button"
                    variant="contained"
                    style={{ backgroundColor: "#171f32" }}
                    onClick={() => this.changeAwayFouls(1)}
                  >
                    +1
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

export default Manual;
