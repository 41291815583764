import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";

const CountdownCheck = () => {
  return (
    <Stack
      style={{ backgroundColor: "gray", borderRadius: "35px", margin: "10px" }}
      alignItems={"center"}
      direction="row"
    >
      <IconButton
        onClick={() => {
          this.setState({
            isTransmitting: !this.state.isTransmitting,
          });
          this.startAction();
        }}
        color="primary"
        size="large"
      >
        <div className="video_icon">
          <PlayArrowIcon fontSize="large" sx={{ color: "white" }} />
        </div>
      </IconButton>
      <p>
        Run one minute on the main clock down to zero, checking decimal
        point/fractions of a second
      </p>
    </Stack>
  );
};
const Diagnostics = (props) => {
  const [VenueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const loadVenueList = () => {
    fetch("/api/loadVenueList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        setVenueList(jsonData);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    loadVenueList();
  }, []);
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "auto 1fr",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="Id-label" sx={{ color: "white" }}>
            Venue
          </InputLabel>
          <Select
            labelId="Id-label"
            value={selectedVenue}
            size="small"
            onChange={(e) => {
              setSelectedVenue(e.target.value);
            }}
            sx={{
              ":before": { borderBottomColor: "white", color: "white" },
              ":after": { borderBottomColor: "white", color: "white" },
              ".MuiSelect-icon": { color: "white" },
              color: "white",
            }}
          >
            {VenueList.map((p, i) => (
              <MenuItem key={i} value={p.Venue}>
                {p.Venue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div>
        <CountdownCheck />
      </div>
    </div>
  );
};

export default Diagnostics;
