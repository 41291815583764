import Board from "..//../components/Common/Board";
import React from "react";
import ScoreModel from "..//../Models//ScoreModel";

class DSTI_Serial extends React.Component {
  constructor(props) {
    super(props);
    this.scoreObj = props.scoreObj;
    this.state = {
      intervalId: {},
      score: props.scoreObj,
      reader: null,
    };
    this.boardRef = {};
    this.interval = 300;
    this.serialProps = {
      baudRate: 9600,
      dataBits: 8,
      stopBits: 1,
      parity: "none",
    };
    this.keepReading = false;
    this.reader = null;
  }
  componentDidMount() {
    this.props.biRef.startAction = this.startAction;
    this.props.biRef.stopAction = this.stopAction;
  }
  connect = async () => {
    console.log("opening port");
    const ports = await navigator.serial.getPorts();
    var port = {};
    if (ports.length == 0) {
      var port = await navigator.serial.requestPort();
    } else port = ports[0];
    console.log(port.getInfo());
    await port.open(this.serialProps);

    this.port = port;
    this.keepReading = true;

    this.readData();
  };

  getValidData = (dataArray) => {
    var startIndex = -1;
    var endIndex = -1;
    // var result = this.Utf8ArrayToStr(dataArray);
    // console.log(result);
    for (var i = 0; i < dataArray.length; i++) {
      if (dataArray[i] == 1) {
        startIndex = i;
      }
      if (dataArray[i] == 4 && i - startIndex >= 30) {
        endIndex = i;
      }
      if (startIndex > 0 && endIndex > 0 && endIndex > startIndex) break;
    }

    return { s: startIndex, e: endIndex };
  };
  readData = async () => {
    while (this.port.readable && this.keepReading) {
      this.reader = this.port.readable.getReader();
      try {
        var dataArray = [];
        while (true) {
          //console.log("reading");
          const { value, done } = await this.reader.read();
          //console.log("reading done" + done);
          if (done) {
            // reader.cancel() has been called.
            break;
          }
          //console.log("read data");
          dataArray.push(...value);
          var dataIndex = this.getValidData(dataArray);
          if (dataIndex.s >= 0 && dataIndex.e >= 0) {
            var validData = dataArray.slice(dataIndex.s + 1, dataIndex.e - 1);
            this.scoreObj.format_DSTI(validData);
            this.setState({ score: this.scoreObj });
            this.props.setScoreObj(this.scoreObj);
            if (dataIndex.e < dataArray.length - 1)
              dataArray = dataArray.slice(dataIndex.e);
            else dataArray = [];
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.reader.releaseLock();
      }
    }
    if (this.port.readable) {
      await this.port.close();
    }
  };
  close = async () => {
    this.keepReading = false;
    this.reader.cancel();
  };

  startAction = () => {
    this.connect();
    this.boardRef.startTransmitting();
  };
  stopAction = () => {
    this.close();
    this.boardRef.stopTransmitting();
  };

  render() {
    return (
      <Board
        ComputerID={this.props.ComputerID}
        biRef={this.boardRef}
        score={this.state.score}
        selectedLanguage={this.props.selectedLanguage}
        setIsConnected={(status) => this.props.setIsConnected(status)}
        setIsTransmitting={(status) => this.props.setIsTransmitting(status)}
        setConfig={(config) => this.props.setConfig(config)}
      />
    );
  }
}

export default DSTI_Serial;
