import "./App.css";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import React, { useEffect, useState } from "react";
import Manual from "./components/Manual/Manual";
import Receive from "./components/Receive/Receive";
import Receive_Edit from "./components/Receive/Receive-Edit";
import { styled } from "@mui/material/styles";
import { SketchPicker } from "react-color";
import Nevco from "./components/Nevco/nevco";
import Cookies from "js-cookie";
import DSTI_Wireless from "./components/DSTI_Wireless/DSTI_Wireless";
import OES from "./components/OES/OES";
import Config from "./components/Config/Config";
import ScoreModel from "./Models/ScoreModel";
import localizedStrings from "./localization/localizedStrings";
import DSTI_Wired from "./components/DSTI_Wired/DSTI_Wired";
import DSTI_Serial from "./components/DSTI_Serial/DSTI_Serial";
import DeleteOutlineOutlined from "@mui/icons-material/DeleteOutlineOutlined";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import "./MainPage.css";
import { config } from "@fullcalendar/react";

var testFunc = () => {
  if ("serial" in navigator) {
    // The Web Serial API is supported.
    console.log("serial supported");
  }
};
const Input = styled("input")({
  display: "none",
});

function IDDialog(props) {
  const [selectedVenue, setSelectedVenue] = useState("");

  return (
    <Dialog open={props.open}>
      <DialogTitle>
        <span style={{ fontWeight: "bold" }}>Venue Selection</span>
      </DialogTitle>
      <DialogContent>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="Id-label">Venue</InputLabel>
          <Select
            labelId="Id-label"
            value={selectedVenue}
            onChange={(e) => {
              setSelectedVenue(e.target.value);
            }}
          >
            {props?.data?.map((p, i) => (
              <MenuItem key={i} value={p.Venue}>
                {p.Venue}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            Cookies.set("VenueID", selectedVenue, { expires: 1000000 });
            props.handleClose(selectedVenue);
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

class MainPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTransmitting: false,
      isConnected: false,
      isReceiving: false,
      isReceiveEdit: false,
      computerID: null,
      venue: "",
      config: {
        selectedMode: "MANUAL",
        selectedGame: "BASKETBALL",
        receiveComputerID: "NA",
        Color: "white",
        backgroundImg: "",
        useELAM: true,
        TargetIncrement: 9,
        TargetPreword: "TGT",
        TargetTimeMark: "04:00",
        overrideELAM: false,
        overrideShowELAM: false,
        overrideTarget: 0,
        isOverridePeriod: false,
        overridePeriod: "1",
        isUppercasePeriod: false,
        selectedLanguage: "en",
        receiveDelay: 0,
      },
      showClrPicker: false,
      showConfigDialog: false,
      scoreObj: new ScoreModel(),
      refreshChild: false,
      showIDDialog: false,
      showVenueDialog: false,
      openStatus: true,
      openRaw: true,
      openJson: true,
      openXml: true,
      openClock: false,
      VenueList: [],
      //sizeChanged: false,
    };
    // this.serverIP = "localhost:3000"; //"94.176.235.224:3000"; //"localhost:3000"; //
    this.receiveRef = {};
    this.receiveEditRef = {};
    this.manualRef = {};
    this.nevcoRef = {};
    this.dsti_wirelessRef = {};
    this.dsti_wiredRef = {};
    this.dsti_serialRef = {};
    this.oesRef = {};
    this.logText = [];
    this.isLogPlaying = false;
    console.log(this.state.scoreObj);
    //this.state.scoreObj.formatDSTI_Wireless(" 0:00.0s      1  7 4 3303202125");
  }

  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  // getComputerID = async () => {
  //   const Id = await biri();
  //   console.log(Id);
  //   this.setState({ computerID: Id });
  //   this.loadConfig(Id);
  // };
  getComputerID = () => {
    // var Venue = Cookies.get("TransmitID");
    // if (Venue == undefined) {
    //   this.setState({ showIDDialog: true, showVenueDialog: true });
    // } else {
    //   this.setState({ computerID: Venue });
    //   this.loadConfig(Venue);
    // }
    var ID = Cookies.get("ComputerID");
    console.log(ID);

    fetch("/api/getComputerID/?id=" + ID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((Id) => {
        this.setState({ computerID: Id });
        this.loadConfig(Id);
        Cookies.set("ComputerID", Id, { expires: 1000000 });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    this.getComputerID();
    this.loadVenueList();
    document.addEventListener("keydown", this._handleKeyDown);
    // this.loadData();
  }
  async loadData() {
    const params = new URLSearchParams();
    params.append("client_id", "evan@kosiner.com");
    params.append(
      "client_secret",
      "y9KuqN6nYECygcfCuk1VfVHnQv4bHEoZcgh6K/TDOOg="
    );
    params.append("grant_type", "client_credentials");
    params.append("sisapi", "sisapi");

    const response = await fetch(
      "https://auth.sportsinfosolutions.com/connect/token",
      {
        method: "POST",
        body: params,
      }
    );
    const data = await response.json();
    var token = data["access_token"];

    await this.delay(1000);

    // var headers = new Headers();
    // headers.append("Content-Type", "application/json");
    // headers.append("Authorization", token);
    // var headers = { Authorization: `Bearer ${token}` };
    const teamInfoResponse = await fetch(
      "https://api.sportsinfosolutions.com/api/v2/sportagnostic/Leagues/7/teams",
      {
        headers: { authorization: `Bearer ${token}`, accept: "*/*" },
      }
    );
    var teamInfo = await teamInfoResponse.json();
    console.log(teamInfo);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this._handleKeyDown);
  }
  _handleKeyDown = (evnt) => {
    if (this.state.showConfigDialog) return;
    switch (evnt.keyCode) {
      case 69:
      case 101:
        this.setState({ isReceiveEdit: !this.state.isReceiveEdit });
        break;
      default:
        break;
    }
  };
  loadConfig = (Id) => {
    fetch("/api/loadConfig/?id=" + Id, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((jsonData) => {
        // this.state.scoreObj.setID(Id);
        // this.state.scoreObj.setVenue(venue);
        var configObj = JSON.parse(jsonData);
        this.state.scoreObj.setConfig(configObj);
        localizedStrings.setLanguage(configObj.selectedLanguage);
        this.state.scoreObj.setLocalizedStrings(localizedStrings);
        this.setState({
          config: configObj,
          scoreObj: this.state.scoreObj,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  saveConfig = async () => {
    this.state.scoreObj.setConfig(this.state.config);
    await fetch("/api/saveConfig/?id=" + this.state.computerID, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.config),
    });
  };
  deleteLayout = async () => {
    await fetch("/api/deleteScorebugLayout/?id=" + this.state.computerID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    });
  };
  startLogPlayback = async () => {
    var mode = this.logText[0].split(":")[0];
    if (mode == "nevco") {
      this.setState({
        config: { ...this.state.config, selectedMode: "NEVCO" },
      });
      var Index = 1;
      this.isLogPlaying = true;
      while (Index < this.logText.length && this.isLogPlaying) {
        var json = JSON.parse(this.logText[Index].split("==>")[1]);
        this.state.scoreObj.formatNEVCOData(json);

        this.setState({ scoreObj: this.state.scoreObj });
        await this.delay(100);
        Index += 1;
        console.log(Index);
      }
    } else if (mode == "DSTI_WIRELESS") {
      this.setState({
        config: { ...this.state.config, selectedMode: "DSTI_WIRELESS" },
      });
      var Index = 1;
      this.isLogPlaying = true;
      while (Index < this.logText.length && this.isLogPlaying) {
        var scoreString = this.logText[Index].split("==>")[1];
        this.state.scoreObj.formatDSTI_Wireless(scoreString);

        this.setState({ scoreObj: this.state.scoreObj });
        await this.delay(10);
        Index += 1;
        console.log(Index);
      }
    }
  };
  startLogging = async () => {
    await fetch("/api/startLogging/?id=" + this.state.computerID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    });
  };
  stopLogging = async () => {
    await fetch("/api/stopLogging/?id=" + this.state.computerID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    });
  };
  startAction = async () => {
    if (this.state.config.selectedMode == "MANUAL") {
      this.manualRef.startTransmitting();
    } else if (this.state.config.selectedMode == "RECEIVE") {
      if (this.state.isReceiveEdit) this.receiveEditRef.startReceiving();
      else this.receiveRef.startReceiving();
    } else if (this.state.config.selectedMode == "NEVCO") {
      this.nevcoRef.startAction();
    } else if (
      this.state.config.selectedMode == "DSTI_WIRELESS" ||
      this.state.config.selectedMode == "DSTI_B"
    ) {
      this.dsti_wirelessRef.startAction();
    } else if (this.state.config.selectedMode == "DSTI_WIRED") {
      this.dsti_wiredRef.startAction();
    } else if (this.state.config.selectedMode == "DSTI_SERIAL") {
      this.dsti_serialRef.startAction();
    } else if (this.state.config.selectedMode == "OES") {
      this.oesRef.startAction();
    } else if (this.state.config.selectedMode == "LOG") {
      if (this.logText.length > 0) {
        this.startLogPlayback();
      }
    }
    if (
      this.state.config.selectedMode !== "RECEIVE" &&
      this.state.config.selectedMode !== "LOG"
    ) {
      this.startLogging();
    }
  };

  stopAction = () => {
    if (this.isLogPlaying) {
      this.isLogPlaying = false;
      this.setState({
        config: { ...this.state.config, selectedMode: "LOG" },
      });
    } else {
      if (this.state.config.selectedMode == "MANUAL") {
        this.manualRef.stopTransmitting();
      } else if (this.state.config.selectedMode == "RECEIVE") {
        if (this.state.isReceiveEdit) this.receiveEditRef.stopReceiving();
        else this.receiveRef.stopReceiving();
      } else if (this.state.config.selectedMode == "NEVCO") {
        this.nevcoRef.stopAction();
      } else if (
        this.state.config.selectedMode == "DSTI_WIRELESS" ||
        this.state.config.selectedMode == "DSTI_B"
      ) {
        this.dsti_wirelessRef.stopAction();
      } else if (this.state.config.selectedMode == "DSTI_WIRED") {
        this.dsti_wiredRef.stopAction();
      } else if (this.state.config.selectedMode == "DSTI_SERIAL") {
        this.dsti_serialRef.stopAction();
      } else if (this.state.config.selectedMode == "OES") {
        this.oesRef.stopAction();
      }

      if (
        this.state.config.selectedMode !== "RECEIVE" &&
        this.state.config.selectedMode !== "LOG"
      ) {
        this.stopLogging();
      }
    }
  };

  loadVenueList = () => {
    fetch("/api/loadVenueList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        this.setState({ VenueList: jsonData });
        // setVenueList(jsonData)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // arrayBufferToBase64 = (buffer) => {
  //   var binary = "";
  //   var bytes = new Uint8Array(buffer);
  //   var len = bytes.byteLength;
  //   for (var i = 0; i < len; i++) {
  //     binary += String.fromCharCode(bytes[i]);
  //   }
  //   return window.btoa(binary);
  // };
  // handleImage = (event) => {
  //   const reader = new FileReader();
  //   reader.onload = async (e) => {
  //     this.setState(
  //       {
  //         config: {
  //           ...this.state.config,
  //           backgroundImg: this.arrayBufferToBase64(e.target.result),
  //         },
  //       },
  //       () => this.saveConfig()
  //     );
  //   };
  //   reader.readAsArrayBuffer(event.target.files[0]);
  // };

  handleLogFile = (event) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      this.logText = e.target.result.split("\r\n");
    };
    reader.readAsText(event.target.files[0]);
  };
  hadleDialogClose = (args, config) => {
    this.setState({ showConfigDialog: false });
    if (args) {
      localizedStrings.setLanguage(config.selectedLanguage);
      if (config.Venue != this.state.computerID) {
        Cookies.set("VenueID", config.Venue, { expires: 1000000 });
        // this.setState({ computerID: config.Venue });
      }
      this.setState(
        {
          config: {
            ...this.state.config,
            useELAM: config.useELAM,
            TargetIncrement: parseInt(config.TargetIncrement),
            TargetPreword: config.TargetPreword,
            overrideELAM: config.overrideELAM,
            overrideShowELAM: config.overrideShowELAM,
            overrideTarget: config.overrideTarget,
            isOverridePeriod: config.isOverridePeriod,
            overridePeriod: config.overridePeriod,
            isUppercasePeriod: config.isUppercasePeriod,
            TargetTimeMark: config.TargetTimeMark,
            selectedLanguage: config.selectedLanguage,
            receiveDelay: config.receiveDelay ?? 0,
            noOfFoulsPerPenalty: config.noOfFoulsPerPenalty,
            penaltyWord: config.penaltyWord,
            penaltyColor: config.penaltyColor,
            isLineTOL: config.isLineTOL,
            showFoulsText: config.showFoulsText,
            // ID: config.ID,
            Venue: config.Venue,
          },
          refreshChild: !this.state.refreshChild,
        },
        () => this.saveConfig()
      );
    }
  };

  setResult = (score) => {
    this.setState({ scoreObj: this.state.scoreObj });
    // console.log(this.state.scoreObj);
    // console.log(score);
    // this.setState({
    //   isHalf: score.IsHalf,
    //   isFinal: score.IsFinal,
    //   RawString: score.RawString,
    //   isELAM: score.IsELAM,
    // });
  };
  setFlags = (isHalf, isELAM, isFinal) => {
    var scoreObj = {
      ...this.state.scoreObj,
      IsHalf: isHalf,
      IsFinal: isFinal,
      IsELAM: isELAM,
    };
    this.setState({ scoreObj: scoreObj });
  };

  render() {
    // const toggleStates = (openState) => {
    //   this.setState({
    //     openStatus: false,
    //     openRaw: false,
    //     openJson: false,
    //     openClock: false,
    //   });
    //   openState === "Status" &&
    //     this.setState({ openStatus: !this.state.openStatus });
    //   openState === "Raw" && this.setState({ openRaw: !this.state.openRaw });
    //   openState === "Json" && this.setState({ openJson: !this.state.openJson });
    //   openState === "Clock" &&
    //     this.setState({ openClock: !this.state.openClock });
    // };
    return (
      <Stack direction="row" spacing={2} className="parentDiv">
        <Stack
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          className="sideBar"
          padding="1rem"
        >
          <Stack
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            className="sideBarTop"
          >
            <div className="sideBarTopCir"></div>
            <div className="sideBarTopRect"></div>
          </Stack>
          {!this.state.showConfigDialog && (
            <Stack>
              {/* <Stack
                key="Status"
                className={
                  this.state.openStatus ? "sideBarMidClick" : "sideBarMid"
                }
                sx={{ marginTop: "0.5rem" }}
                direction="column"
              >
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ color: "white" }}>Status</Typography>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.setState({ openStatus: !this.state.openStatus })
                    }
                  >
                    {this.state.openStatus ? (
                      <ArrowDropUpIcon sx={{ color: "white" }} />
                    ) : (
                      <ArrowDropDownIcon sx={{ color: "white" }} />
                    )}
                  </div>
                </Stack>
                {this.state.openStatus && (
                  <Stack
                    direction="column"
                    sx={{ paddingTop: "0.5rem" }}
                    justifyContent="space-between"
                  >
                    <Stack direction="row" justifyContent="space-between">
                      <Typography
                        sx={{
                          color: this.state.isConnected ? "#52cb65" : "#ff4d44",
                        }}
                      >
                        {localizedStrings.primary_server}
                      </Typography>
                      <Box
                        className="primary_server"
                        sx={{
                          bgcolor: this.state.isConnected
                            ? "#52cb65"
                            : "#ff4d44",
                        }}
                      ></Box>
                    </Stack>
                    <Stack
                      direction="row"
                      sx={{ paddingTop: "0.5rem" }}
                      justifyContent="space-between"
                    >
                      <Typography
                        sx={{
                          color: this.state.isConnected ? "#ff4d44" : "#52cb65",
                        }}
                      >
                        {localizedStrings.backup_server}
                      </Typography>
                      <Box
                        className="primary_server"
                        sx={{
                          bgcolor: this.state.isConnected
                            ? "#ff4d44"
                            : "#52cb65",
                        }}
                      ></Box>
                    </Stack>
                  </Stack>
                )} 
              </Stack> */}
              {this.state.config.selectedMode !== "RECEIVE" &&
                this.state.config.selectedMode !== "LOG" && (
                  <>
                    <Stack
                      key="Raw Data"
                      className={
                        this.state.openRaw ? "sideBarMidClick" : "sideBarMid"
                      }
                      sx={{ marginTop: "0.5rem" }}
                      direction="column"
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ color: "white" }}>
                          Raw Data
                        </Typography>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ openRaw: !this.state.openRaw })
                          }
                        >
                          {this.state.openRaw ? (
                            <ArrowDropUpIcon sx={{ color: "white" }} />
                          ) : (
                            <ArrowDropDownIcon sx={{ color: "white" }} />
                          )}
                        </div>
                      </Stack>
                      {this.state.openRaw && (
                        <Stack
                          direction="column"
                          sx={{ paddingTop: "0.5rem" }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography sx={{ color: "white" }}>
                            {this.state.scoreObj.RawString}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                    <Stack
                      key="JSON Data"
                      className={
                        this.state.openJson ? "sideBarMidClick" : "sideBarMid"
                      }
                      sx={{ marginTop: "0.5rem" }}
                      direction="column"
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ color: "white" }}>
                          JSON Data
                        </Typography>
                        <div
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            this.setState({ openJson: !this.state.openJson })
                          }
                        >
                          {this.state.openJson ? (
                            <ArrowDropUpIcon sx={{ color: "white" }} />
                          ) : (
                            <ArrowDropDownIcon sx={{ color: "white" }} />
                          )}
                        </div>
                      </Stack>
                      {this.state.openJson && (
                        <Stack
                          direction="column"
                          sx={{ paddingTop: "0.5rem" }}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography sx={{ color: "white" }}>
                            {JSON.stringify(
                              this.state.scoreObj.getData(),
                              null,
                              1
                            )}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>

                    <Stack
                      className="sideBarMid"
                      sx={{ marginTop: "0.5rem" }}
                      direction="column"
                    >
                      <Button
                        onClick={() =>
                          window
                            .open(
                              "/api/getxml?id=" + this.state.computerID,
                              "_blank"
                            )
                            .focus()
                        }
                        variant="contained"
                      >
                        Open Server XML
                      </Button>
                    </Stack>
                    {this.state.config.selectedMode !== "MANUAL" && (
                      <Stack
                        className="sideBarMid"
                        sx={{ marginTop: "0.5rem", gap: "10px" }}
                        direction="column"
                      >
                        <Button
                          onClick={() =>
                            window
                              .open(
                                "/api/getjson?id=" + this.state.computerID,
                                "_blank"
                              )
                              .focus()
                          }
                          variant="contained"
                        >
                          Open Server JSON
                        </Button>
                        <Button
                          onClick={() =>
                            window
                              .open(
                                "/targetsetting/id=" + this.state.computerID,
                                "_blank"
                              )
                              .focus()
                          }
                          variant="contained"
                        >
                          Open Server TARGET
                        </Button>
                      </Stack>
                    )}
                  </>
                )}
              {/* {(this.state.config.selectedMode == "RECEIVE" && this.state.isReceiveEdit) &&
                <Stack key="Clock Properties" className= { this.state.openClock ? "sideBarMidClick": "sideBarMid"} sx={{marginTop:"0.5rem"}} direction="column">
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{color:"white"}}>Clock Properties</Typography>
                  <div style={{cursor: "pointer"}} onClick={()=>toggleStates("Clock")} >
                  {this.state.openClock ?
                      <ArrowDropUpIcon sx={{color: "white"}} /> : <ArrowDropDownIcon sx={{color:"white"}}/>
                  }
                  </div>
                </Stack>
                { this.state.openClock &&
                  <Stack direction="column" sx={{paddingTop: "0.5rem"}} justifyContent="space-between">
                    <Stack direction="row" justifyContent="space-between">
                      <Typography sx={{color: this.state.isConnected? "#52cb65" : "#ff4d44"}}>{localizedStrings.primary_server}</Typography>
                      <Box className="primary_server" sx={{bgcolor: this.state.isConnected? "#52cb65" : "#ff4d44"}}></Box>
                    </Stack> 
                    <Stack direction="row" sx={{paddingTop: "0.5rem"}} justifyContent="space-between">
                      <Typography sx={{color: this.state.isConnected? "#ff4d44" : "#52cb65" }}>{localizedStrings.backup_server}</Typography>
                      <Box className="primary_server" sx={{bgcolor: this.state.isConnected? "#ff4d44" : "#52cb65"}}></Box>
                    </Stack>
                  </Stack>
                }
                </Stack>
              } */}
            </Stack>
          )}
          {this.state.showConfigDialog && (
            <Stack
              key="Status"
              className={
                this.state.openStatus ? "sideBarMidClick" : "sideBarMid"
              }
              sx={{ marginTop: "0.5rem" }}
              direction="column"
            >
              <Stack direction="row" justifyContent="space-between">
                <Config
                  config={this.state.config}
                  Venue={this.state.computerID}
                  handleDialogClose={this.hadleDialogClose}
                />
              </Stack>
            </Stack>
          )}
          <Stack spacing={2}>
            {this.state.config.selectedMode == "MANUAL" && (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#2b3382;",
                  marginTop: "0.5rem",
                  textTransform: "none",
                }}
                className="button_options"
              >
                Enable HotKeys
              </Button>
            )}
            <Button
              variant="contained"
              sx={{
                bgcolor: "#21325b;",
                marginTop: "0.5rem",
                textTransform: "none",
              }}
              className="button_options"
              onClick={() =>
                this.setState({
                  // openStatus: false,
                  // openRaw: false,
                  // openJson: false,
                  // openClock: false,
                  showConfigDialog: !this.state.showConfigDialog,
                })
              }
            >
              {this.state.showConfigDialog ? "Back" : "Settings"}
            </Button>
            {sessionStorage.getItem("authenticated") && (
              <Button
                variant="contained"
                sx={{
                  bgcolor: "#21325b;",
                  marginTop: "0.5rem",
                  textTransform: "none",
                }}
                className="button_options"
                onClick={() => {
                  sessionStorage.clear();
                }}
              >
                Logout
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack
          direction="column"
          justifyContent="space-around"
          flex={1}
          className="mainContainer"
          spacing={1}
        >
          <div style={{ display: "grid" }}>
            {this.state.config.selectedMode == "MANUAL" && (
              <Manual
                ComputerID={this.state.computerID}
                biRef={this.manualRef}
                setResult={this.setResult}
                config={this.state.config}
                scoreObj={this.state.scoreObj}
                key={this.state.refreshChild}
                setIsTransmitting={(status) =>
                  this.setState({ isTransmitting: status })
                }
                setIsConnected={(status) =>
                  this.setState({ isConnected: status })
                }
                setScoreObj={(score) => this.setState({ scoreObj: score })}
                setConfig={(config) => this.state.scoreObj.setConfig(config)}
              />
            )}
            {this.state.config.selectedMode == "RECEIVE" &&
              this.state.isReceiveEdit && (
                <Receive_Edit
                  ComputerID={this.state.computerID}
                  selectedLanguage={this.state.config.selectedLanguage}
                  IsMasterTemplate={false}
                  biRef={this.receiveEditRef}
                  ReceiveComputerID={this.state.config.receiveComputerID}
                  venue={this.state.config.Venue}
                  receiveDelay={this.state.config.receiveDelay}
                  setIsReceiving={(status) =>
                    this.setState({ isTransmitting: status })
                  }
                  isReceiving={this.state.isReceiving}
                  config={this.state.config}
                />
              )}
            {this.state.config.selectedMode == "RECEIVE" &&
              !this.state.isReceiveEdit && (
                <Receive
                  ComputerID={this.state.computerID}
                  ReceiveComputerID={this.state.config.receiveComputerID}
                  selectedLanguage={this.state.config.selectedLanguage}
                  isUppercasePeriod={this.state.config.isUppercasePeriod}
                  biRef={this.receiveRef}
                  setIsReceiving={(status) =>
                    this.setState({ isTransmitting: status })
                  }
                  isReceiving={this.state.isReceiving}
                  setReceiveID={(ID) =>
                    this.setState(
                      {
                        config: { ...this.state.config, receiveComputerID: ID },
                      },
                      () => this.saveConfig()
                    )
                  }
                  receiveDelay={this.state.config.receiveDelay}
                  config={this.state.config}
                />
              )}
            {this.state.config.selectedMode == "NEVCO" && (
              <Nevco
                url={this.state.config.nevcourl}
                ComputerID={this.state.computerID}
                biRef={this.nevcoRef}
                scoreObj={this.state.scoreObj}
                selectedLanguage={this.state.config.selectedLanguage}
                setFlags={this.setFlags}
                setIsTransmitting={(status) =>
                  this.setState({ isTransmitting: status })
                }
                setIsConnected={(status) =>
                  this.setState({ isConnected: status })
                }
                setScoreObj={(score) => this.setState({ scoreObj: score })}
                setConfig={(config) => this.state.scoreObj.setConfig(config)}
              />
            )}
            {(this.state.config.selectedMode == "DSTI_WIRELESS" ||
              this.state.config.selectedMode == "DSTI_B") && (
              <DSTI_Wireless
                ComputerID={this.state.computerID}
                biRef={this.dsti_wirelessRef}
                scoreObj={this.state.scoreObj}
                selectedLanguage={this.state.config.selectedLanguage}
                setFlags={this.setFlags}
                selectedMode={this.state.config.selectedMode}
                setIsTransmitting={(status) =>
                  this.setState({ isTransmitting: status })
                }
                setIsConnected={(status) =>
                  this.setState({ isConnected: status })
                }
                setScoreObj={(score) => this.setState({ scoreObj: score })}
                setConfig={(config) => this.state.scoreObj.setConfig(config)}
              />
            )}
            {this.state.config.selectedMode == "DSTI_WIRED" && (
              <DSTI_Wired
                ComputerID={this.state.computerID}
                biRef={this.dsti_wiredRef}
                scoreObj={this.state.scoreObj}
                selectedLanguage={this.state.config.selectedLanguage}
                setFlags={this.setFlags}
                setIsTransmitting={(status) =>
                  this.setState({ isTransmitting: status })
                }
                setIsConnected={(status) =>
                  this.setState({ isConnected: status })
                }
                setScoreObj={(score) => this.setState({ scoreObj: score })}
                setConfig={(config) => this.state.scoreObj.setConfig(config)}
              />
            )}
            {this.state.config.selectedMode == "DSTI_SERIAL" && (
              <DSTI_Serial
                ComputerID={this.state.computerID}
                biRef={this.dsti_serialRef}
                scoreObj={this.state.scoreObj}
                selectedLanguage={this.state.config.selectedLanguage}
                setScoreObj={(score) => this.setState({ scoreObj: score })}
                setConfig={(config) => this.state.scoreObj.setConfig(config)}
              />
            )}
            {this.state.config.selectedMode == "OES" && (
              <OES
                ComputerID={this.state.computerID}
                biRef={this.oesRef}
                scoreObj={this.state.scoreObj}
                selectedLanguage={this.state.config.selectedLanguage}
                setFlags={this.setFlags}
                setIsTransmitting={(status) =>
                  this.setState({ isTransmitting: status })
                }
                setIsConnected={(status) =>
                  this.setState({ isConnected: status })
                }
                setScoreObj={(score) => this.setState({ scoreObj: score })}
                setConfig={(config) => this.state.scoreObj.setConfig(config)}
              />
            )}
            {this.state.showIDDialog && (
              <IDDialog
                open={this.state.showIDDialog}
                handleClose={(computerID) => {
                  this.setState({
                    showIDDialog: false,
                    computerID: computerID,
                  });
                }}
                data={this.state.VenueList}
              />
            )}
          </div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-evenly"
            className="footer"
          >
            <Stack direction="row" alignItems="center" spacing={4}>
              <div>
                <Typography sx={{ color: "white", paddingLeft: "0.5rem" }}>
                  Mode
                </Typography>
                <FormControl
                  disabled={this.state.isTransmitting}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  className="flex_space"
                >
                  <Select
                    labelId="mode-label"
                    className="mode"
                    label="Mode"
                    style={{ color: "white" }}
                    sx={{ ".MuiSelect-icon": { color: "white" } }}
                    value={this.state.config.selectedMode}
                    disableUnderline
                    onChange={(event) => {
                      this.setState(
                        {
                          config: {
                            ...this.state.config,
                            selectedMode: event.target.value,
                          },
                        },
                        () => this.saveConfig()
                      );
                    }}
                  >
                    <MenuItem value={"MANUAL"}>MANUAL</MenuItem>
                    <MenuItem value={"NEVCO"}>NEVCO (IP/XML)</MenuItem>
                    <MenuItem value={"RECEIVE"}>RECEIVE & DISPLAY</MenuItem>
                    <MenuItem value={"DSTI_WIRELESS"}>
                      DAKTRONICS WIRELESS
                    </MenuItem>
                    <MenuItem value={"DSTI_B"}>DAKTRONICS B</MenuItem>
                    <MenuItem value={"OES"}>OES (SERIAL PORT)</MenuItem>
                    <MenuItem value={"DSTI_WIRED"}>DAKTRONICS WIRED</MenuItem>
                    <MenuItem value={"LOG"}>LOG PLAYBACK</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                <Typography sx={{ color: "white", paddingLeft: "0.5rem" }}>
                  Game
                </Typography>
                <FormControl
                  disabled={this.state.isTransmitting}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <Select
                    labelId="game-label"
                    className="mode"
                    style={{ color: "white" }}
                    value={this.state.config.selectedGame}
                    label="Game"
                    disableUnderline
                    sx={{ ".MuiSelect-icon": { color: "white" } }}
                  >
                    <MenuItem value={"BASKETBALL"}>BASKETBALL</MenuItem>
                    <MenuItem value={"HOCKEY"}>HOCKEY</MenuItem>
                    <MenuItem value={"OTHERS"}>OTHERS</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                {this.state.config.selectedMode != "RECEIVE" && (
                  <div style={{ display: "grid" }}>
                    <FormControlLabel
                      style={{ color: "white", textAlign: "left" }}
                      label="FINAL"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={this.state.scoreObj.IsFinal}
                          style={{ color: "white" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                          onClick={(e) => {
                            this.state.scoreObj.IsFinal = e.target.checked;
                            this.setState({ scoreObj: this.state.scoreObj });
                          }}
                        />
                      }
                    />
                    <FormControlLabel
                      style={{ color: "white", textAlign: "left" }}
                      label="HALF"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={this.state.scoreObj.IsHalf}
                          style={{ color: "white" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                      }
                    />
                    <FormControlLabel
                      style={{ color: "white", textAlign: "left" }}
                      label="ELAM"
                      labelPlacement="start"
                      control={
                        <Checkbox
                          checked={this.state.scoreObj.IsELAM}
                          style={{ color: "white" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                      }
                    />
                  </div>
                )}
              </div>
              {/* {this.state.config.selectedMode == "RECEIVE" && (
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      paddingLeft: "0.5rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Receiver Venue
                  </Typography>
                  <FormControl
                    disabled={this.state.isTransmitting}
                    variant="standard"
                    className="mode"
                    sx={{
                      input: {
                        color: "white",
                        width: "180px",
                        paddingLeft: "1rem",
                        marginTop: "0.5rem",
                      },
                    }}
                  >
                    <Select
                      labelId="game-label"
                      className="mode"
                      style={{ color: "white" }}
                      value={this.state.config.receiveComputerVenue}
                      onChange={(ev) => {
                        this.setState({
                          config: {
                            ...this.state.config,
                            receiveComputerVenue: ev.target.value,
                          },
                        });
                        this.saveConfig();
                        ev.preventDefault();
                      }}
                      label="receiver_id"
                      disableUnderline
                      sx={{ ".MuiSelect-icon": { color: "white" } }}
                    >
                      {this.state.VenueList?.map((item) => (
                        <MenuItem key={item.ID} value={item.Venue}>
                          {item.Venue}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div></div>
                </div>
              )} */}
              {this.state.config.selectedMode == "RECEIVE" && (
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      paddingLeft: "0.5rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    Edit Mode
                  </Typography>
                  <FormControlLabel
                    style={{ color: "white" }}
                    label="Edit Mode"
                    control={
                      <Checkbox
                        style={{ color: "white" }}
                        checked={this.state.isReceiveEdit}
                        onChange={(e) =>
                          this.setState({ isReceiveEdit: e.target.checked })
                        }
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                      />
                    }
                  />
                </div>
              )}
              {this.state.config.selectedMode == "NEVCO" && (
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      paddingLeft: "0.5rem",
                      marginBottom: "0.2rem",
                    }}
                  >
                    NEVCO URL
                  </Typography>
                  <TextField
                    color="secondary"
                    sx={{ input: { color: "white", width: "200px" } }}
                    value={this.state.config.nevcourl}
                    onChange={(ev) =>
                      this.setState({
                        config: {
                          ...this.state.config,
                          nevcourl: ev.target.value,
                        },
                      })
                    }
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        this.saveConfig();
                        ev.preventDefault();
                      }
                    }}
                    variant="standard"
                  />
                </div>
              )}
              {this.state.config.selectedMode == "LOG" && (
                <div>
                  <label htmlFor="log-button-file">
                    <Input
                      id="log-button-file"
                      type="file"
                      onChange={(event) => this.handleLogFile(event)}
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      component="span"
                      endIcon={<InsertDriveFileOutlinedIcon />}
                    >
                      Upload
                    </Button>
                  </label>
                </div>
              )}
            </Stack>
            <div>
              {this.state.isTransmitting && (
                <Stack direction="row">
                  <div>
                    <IconButton
                      onClick={() => {
                        this.setState({
                          isTransmitting: !this.state.isTransmitting,
                        });
                        this.stopAction();
                      }}
                      color="primary"
                      size="large"
                    >
                      <div
                        className="video_icon"
                        style={{ backgroundColor: "green" }}
                      >
                        <StopIcon fontSize="large" sx={{ color: "white" }} />
                      </div>
                    </IconButton>
                  </div>
                  <div
                    style={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {this.state.config.selectedMode != "RECEIVE" && (
                      <h3 style={{ margin: "0px", color: "white" }}>
                        {localizedStrings.transmitting}
                      </h3>
                    )}
                    {this.state.config.selectedMode == "RECEIVE" && (
                      <h3 style={{ margin: "0px", color: "white" }}>
                        {localizedStrings.receiving}
                      </h3>
                    )}
                    <h3
                      style={{ margin: "0px", fontSize: "23px", color: "gray" }}
                    >
                      Venue: {this.state.config.Venue}
                    </h3>
                    <span
                      style={{
                        margin: "0px",
                        color: "gray",
                      }}
                    >
                      {this.state.computerID}
                    </span>
                  </div>
                </Stack>
              )}
              {!this.state.isTransmitting && (
                <Stack direction="row">
                  <IconButton
                    onClick={() => {
                      this.setState({
                        isTransmitting: !this.state.isTransmitting,
                      });
                      this.startAction();
                    }}
                    color="primary"
                    size="large"
                  >
                    <div className="video_icon">
                      <PlayArrowIcon fontSize="large" sx={{ color: "white" }} />
                    </div>
                  </IconButton>
                  <div
                    style={{
                      alignSelf: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h3
                      style={{ margin: "0px", fontSize: "23px", color: "gray" }}
                    >
                      Venue: {this.state.config.Venue}
                    </h3>
                    <span style={{ color: "gray" }}>
                      {this.state.computerID}
                    </span>
                  </div>
                </Stack>
              )}
            </div>
          </Stack>
        </Stack>
      </Stack>
    );
  }
}

export default MainPage;
