import React, { useEffect, useState } from "react";
import "../Login/Login.css";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const TargetLogin = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedID = location.state.split("=")[1];
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  // const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [idList, setIDList] = useState([]);
  // const [selectedIDList, setSelectedIDList] = useState([]);
  // const [selectedID, setSelectedID] = useState("");

  const loadIDList = () => {
    fetch("/api/loadIDList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        var selectedComputer = jsonData.find(
          (item) => item["ID"] == selectedID
        );
        setSelectedVenue(selectedComputer["Venue"]);
        setIDList(jsonData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    loadIDList();
  }, []);

  var handleSubmit = (event) => {
    event.preventDefault();

    var selectedComputer = idList.find((item) => item["ID"] == selectedID);
    if (selectedComputer && password == "9999") {
      sessionStorage.setItem("team_authenticated", true);
      sessionStorage.setItem("teamID", selectedID);
      sessionStorage.setItem("teamVenue", selectedComputer["Venue"]);
      navigate("/targetsetting/id=" + selectedID);
    } else {
      sessionStorage.setItem("team_authenticated", false);
      console.log(sessionStorage.getItem("team_authenticated"));
      setError("Invalid Details");
    }
  };

  return (
    <div>
      <AppBar
        style={{ backgroundColor: "#0c1428" }}
        position="static"
        alignitems="center"
        color="primary"
      >
        <Toolbar>
          <Grid
            container
            style={{ justifyContent: "space-between", alignItems: "center" }}
            wrap="wrap"
          >
            <Grid item>
              <Typography variant="h6">SCOREBUG</Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={0}
        style={{ justifyContent: "center" }}
        direction="row"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            className="login-form"
          >
            <Paper
              variant="elevation"
              elevation={2}
              className="login-background"
            >
              <Grid sx={{ paddingBottom: "20px" }} spacing={2} item>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Grid>
              <Grid item>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      {/* <FormControl
                        sx={{ mt: 2, mb: 2 }}
                        fullWidth
                        variant="standard"
                      >
                        <InputLabel id="language-label">Venue</InputLabel>
                        <Select
                          value={selectedVenue}
                          onChange={(e) => {
                            var filtIDList = idList.filter(
                              (p) =>
                                p["Venue"] &&
                                p["Venue"].toLowerCase() ==
                                  e.target.value.toLowerCase()
                            );
                            setSelectedVenue(e.target.value);
                            setSelectedIDList(filtIDList);
                          }}
                          labelId="language-label"
                          color="primary"
                        >
                          {venueList.map((p, i) => (
                            <MenuItem key={i} value={p.Venue}>
                              {p.Venue}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}

                      {/* <FormControl
                        sx={{ mt: 2, mb: 2 }}
                        fullWidth
                        variant="standard"
                      >
                        <InputLabel id="language-label">ID</InputLabel>
                        <Select
                          value={selectedID}
                          onChange={(e) => {
                            setSelectedID(e.target.value);
                          }}
                          labelId="language-label"
                          color="primary"
                        >
                          {selectedIDList.map((p, i) => (
                            <MenuItem key={i} value={p.ID}>
                              {p.ID}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}

                      <TextField
                        fullWidth
                        variant="standard"
                        value={selectedID}
                        label="Selected ID"
                        {...props}
                        inputProps={{
                          readOnly: true,
                        }}
                        InputProps={{
                          className: "Mui-disabled",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        fullWidth
                        variant="standard"
                        value={selectedVenue}
                        label="Selected Venue"
                        {...props}
                        inputProps={{
                          readOnly: true,
                        }}
                        InputProps={{
                          className: "Mui-disabled",
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        type="password"
                        placeholder="PIN"
                        fullWidth
                        name="password"
                        variant="outlined"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button-block"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <p style={{ color: "orangered" }}>{error}</p>
                  </Grid>
                </form>
              </Grid>
              {/* <Grid item>
                  <Link href="#" variant="body2">
                    Forgot Password?
                  </Link>
                </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TargetLogin;
