import { useEffect, useState } from "react";
import MasterTemplate from "./MasterTemplate";
import MasterSchedule from "./MasterSchedule";
import {
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import ColorizeOutlinedIcon from "@mui/icons-material/ColorizeOutlined";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import SupervisorAccountOutlinedIcon from "@mui/icons-material/SupervisorAccountOutlined";
import { SketchPicker } from "react-color";
import Cookies from "js-cookie";
import "./Admin.css";
import Receive_Edit from "../Receive/Receive-Edit";
import MasterControl from "./MasterControl";

export default function Admin() {
  const [isMasterTemplate, setIsMasterTemplate] = useState(true);
  const [isMasterSchedule, setIsMasterSchedule] = useState(false);
  const [isMasterControl, setIsMasterControl] = useState(false);
  const [showClrPicker, setShowClrPicker] = useState(false);
  const [ComputerID, setComputerID] = useState("NA");
  const [backColor, setBackColor] = useState("white");
  const [backImage, setBackImage] = useState("");
  const [language, setLanguage] = useState("en");
  const [masterTemplate, setMasterTemplate] = useState({});
  // const serverIP = "localhost:3000"; //"94.176.235.224:3000"; //"localhost:3000";

  const getComputerID = () => {
    var ID = Cookies.get("ComputerID");

    ID = ID === undefined ? NaN : ID;

    fetch("/api/getComputerID/?id=" + ID, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((Id) => {
        setComputerID(Id);
        Cookies.set("ComputerID", Id, { expires: 1000000 });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getComputerID();
  }, []);

  const handleDateClick = (arg) => {};
  const handleImage = (e) => {};
  return (
    <Stack className="parentDiv" spacing={2}>
      <Stack
        sx={{ backgroundColor: "#0c1428;", padding: "0.5rem" }}
        direction="row"
        alignItems="center"
        spacing={5}
      >
        {/* <div style={{ marginLeft: "10rem" }}>
          <h5>ID:{ComputerID}</h5>
        </div> */}

        <Stack
          style={{ marginLeft: "10rem" }}
          direction="row"
          alignItems="center"
          spacing={4}
        >
          <div
            style={{ display: "grid", cursor: "pointer" }}
            onClick={() => {
              setIsMasterTemplate(true);
              setIsMasterSchedule(false);
              setIsMasterControl(false);
            }}
          >
            <IconButton
              style={{
                color: "white",
                backgroundColor: isMasterTemplate
                  ? "dodgerblue"
                  : "transparent",
                margin: "7px",
              }}
            >
              <DriveFileRenameOutlineOutlinedIcon
                style={{ width: "50px", height: "50px" }}
              />
            </IconButton>

            <span>TEMPLATE</span>
          </div>

          <div
            onClick={() => {
              setIsMasterTemplate(false);
              setIsMasterControl(false);
              setIsMasterSchedule(true);
            }}
            style={{
              display: "grid",
              justifyItems: "center",
              cursor: "pointer",
            }}
          >
            <IconButton
              style={{
                color: "white",
                backgroundColor: isMasterSchedule
                  ? "dodgerblue"
                  : "transparent",
                margin: "7px",
              }}
            >
              <CalendarTodayOutlinedIcon
                style={{ width: "44px", height: "44px", padding: "3px" }}
              />
            </IconButton>
            <span>LEAGUE SCHEDULE</span>
          </div>
          <div
            onClick={() => {
              setIsMasterTemplate(false);
              setIsMasterSchedule(false);
              setIsMasterControl(true);
            }}
            style={{
              display: "grid",
              justifyItems: "center",
              cursor: "pointer",
            }}
          >
            <IconButton
              style={{
                color: "white",
                backgroundColor: isMasterControl ? "dodgerblue" : "transparent",
                margin: "7px",
              }}
            >
              <SupervisorAccountOutlinedIcon
                style={{ width: "50px", height: "50px" }}
              />
            </IconButton>
            <span>SERVERS</span>
          </div>

          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel style={{ color: "white" }} id="language-label">
              Language
            </InputLabel>
            <Select
              style={{
                color: "white",
              }}
              labelId="language-label"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
                // localizedStrings.setLanguage(e.target.value);
              }}
              sx={{ ".MuiSelect-icon": { color: "white" } }}
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"fr"}>French</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <div
        id="mainPanelAdmin"
        className="mainPanelAdmin"
        style={{ margin: "1rem" }}
      >
        {isMasterTemplate && (
          <MasterTemplate selectedLanguage={language} ComputerID={ComputerID} />
        )}
        {isMasterSchedule && <MasterSchedule />}
        {isMasterControl && <MasterControl />}
      </div>
    </Stack>
  );
}
