import React, { useState } from "react";
import "./Login.css";
import {
  Button,
  TextField,
  Grid,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  Link,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Login = (props) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  var handleSubmit = (event) => {
    event.preventDefault();
    if (username == "admin" && password == "Ba$ketball99!") {
      sessionStorage.setItem("authenticated", true);
      navigate("/admin");
    } else {
      sessionStorage.setItem("authenticated", false);
      console.log(sessionStorage.getItem("authenticated"));
      setError("Invalid Credentials");
    }
  };

  return (
    <div>
      <AppBar
        style={{ backgroundColor: "#0c1428" }}
        position="static"
        alignitems="center"
        color="primary"
      >
        <Toolbar>
          <Grid
            container
            style={{ justifyContent: "space-between", alignItems: "center" }}
            wrap="wrap"
          >
            <Grid item>
              <Typography variant="h6">SCOREBUG</Typography>
            </Grid>

            <div style={{ display: "flex" }}>
              <Button
                style={{ fontSize: "1.25rem" }}
                startIcon={<SettingsInputComponentIcon />}
                variant="outline"
                color="primary"
                type="submit"
                className="button-block"
                onClick={() => {
                  navigate("/diagnostics");
                }}
              >
                DIAGNOSTICS
              </Button>
              <Button
                style={{ fontSize: "1.25rem" }}
                startIcon={<DashboardIcon />}
                variant="outline"
                color="primary"
                type="submit"
                className="button-block"
                onClick={() => {
                  navigate("/main");
                }}
              >
                MAIN
              </Button>
            </div>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={0}
        style={{ justifyContent: "center" }}
        direction="row"
      >
        <Grid item>
          <Grid
            container
            direction="column"
            justify="center"
            spacing={2}
            className="login-form"
          >
            <Paper
              variant="elevation"
              elevation={2}
              className="login-background"
            >
              <Grid item>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
              </Grid>
              <Grid item>
                <form onSubmit={handleSubmit}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        placeholder="Username"
                        fullWidth
                        name="username"
                        variant="outlined"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                        required
                        autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        type="password"
                        placeholder="Password"
                        fullWidth
                        name="password"
                        variant="outlined"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className="button-block"
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <p style={{ color: "orangered" }}>{error}</p>
                  </Grid>
                </form>
              </Grid>
              {/* <Grid item>
                  <Link href="#" variant="body2">
                    Forgot Password?
                  </Link>
                </Grid> */}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}
export default withRouter(Login);
