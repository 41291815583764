import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
  Card,
  FormControlLabel,
  Checkbox,
  Stack,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import localizedStrings from "../../localization/localizedStrings";
import "./Config.css";

export default function Config(props) {
  const [config, setConfig] = useState(props.config);
  const [VenueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [periodList, setPeriodList] = useState(["1", "2", "3", "4"]);
  const getPeriodOrdinal = (period) => {
    if (period == "1") return localizedStrings._1st;
    else if (period == "2") return localizedStrings._2nd;
    else if (period == "3") return localizedStrings._3rd;
    else if (period == "4") return localizedStrings._4th;
  };
  console.log(props.config);

  const loadVenueList = () => {
    fetch("/api/loadVenueList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        setVenueList(JSON.parse(jsonData));
        if (config.Venue) setSelectedVenue(config.Venue);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => loadVenueList(), []);
  return (
    <Stack
      className={
        config.selectedMode == "RECEIVE"
          ? "settingsContainer"
          : "settingsContainerMan"
      }
    >
      <Typography sx={{ color: "white", margin: "5px" }}>Settings</Typography>
      <Stack spacing={1} sx={{ marginTop: "10px", margin: "10px" }}>
        <Stack className="settingsRect">
          <FormControl
            variant="standard"
            sx={{ color: "white", m: 1, minWidth: 120 }}
          >
            <InputLabel id="language-label" sx={{ color: "white" }}>
              Language
            </InputLabel>
            <Select
              labelId="language-label"
              value={config.selectedLanguage}
              onChange={(e) => {
                setConfig({
                  ...config,
                  selectedLanguage: e.target.value,
                });
                localizedStrings.setLanguage(e.target.value);
              }}
              color="primary"
              // InputLabelProps={{
              //   sx:{
              //     color:"white"
              //   }
              // }}
              sx={{
                ":before": { borderBottomColor: "white", color: "white" },
                ":after": { borderBottomColor: "white" },
                ".MuiSelect-icon": { color: "white" },
                color: "white",
              }}
              size="small"
            >
              <MenuItem value={"en"}>English</MenuItem>
              <MenuItem value={"fr"}>French</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {config.selectedMode == "RECEIVE" && (
          <>
            <Stack className="settingsRect">
              <TextField
                value={config.receiveDelay}
                onChange={(e) => {
                  setConfig({ ...config, receiveDelay: e.target.value });
                }}
                variant="standard"
                label="Receive Delay (sec)"
                size="small"
                sx={{
                  margin: "5px",
                  marginLeft: "10px",
                }}
                InputProps={{ sx: { color: "white" } }}
                InputLabelProps={{ sx: { color: "white" } }}
              />
            </Stack>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Upper case
              </Typography>
              <Checkbox
                checked={config.isUppercasePeriod}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    isUppercasePeriod: e.target.checked,
                  })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Line TOL
              </Typography>
              <Checkbox
                checked={config.isLineTOL}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    isLineTOL: e.target.checked,
                  })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>

            <Stack className="settingsRect">
              <TextField
                value={config.noOfFoulsPerPenalty}
                onChange={(e) => {
                  setConfig({
                    ...config,
                    noOfFoulsPerPenalty: e.target.value,
                  });
                }}
                variant="standard"
                label="Fouls For Penalty"
                size="small"
                sx={{ margin: "5px", color: "white", marginLeft: "10px" }}
                InputProps={{ sx: { color: "white" } }}
                InputLabelProps={{ sx: { color: "white" } }}
              />
            </Stack>
            <Stack className="settingsRect">
              <FormControl variant="standard" sx={{ color: "white", m: 1 }}>
                <InputLabel id="penaltyWord-label" sx={{ color: "white" }}>
                  Penalty Word
                </InputLabel>
                <Select
                  labelId="penaltyWord-label"
                  value={config.penaltyWord}
                  onChange={(e) => {
                    setConfig({
                      ...config,
                      penaltyWord: e.target.value,
                    });
                  }}
                  sx={{
                    ":before": { borderBottomColor: "white", color: "white" },
                    ":after": { borderBottomColor: "white" },
                    ".MuiSelect-icon": { color: "white" },
                    color: "white",
                  }}
                >
                  <MenuItem value={"bonus"}>BONUS</MenuItem>
                  <MenuItem value={"penalty"}>PENALTY</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack className="settingsRect">
              <TextField
                value={config.penaltyColor}
                onChange={(e) => {
                  setConfig({
                    ...config,
                    penaltyColor: e.target.value,
                  });
                }}
                variant="standard"
                label="Penalty Color"
                size="small"
                sx={{ margin: "5px", color: "white", marginLeft: "10px" }}
                InputProps={{ sx: { color: "white" } }}
                InputLabelProps={{ sx: { color: "white" } }}
              />
            </Stack>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Show "Fouls"
              </Typography>
              <Checkbox
                checked={config.showFoulsText}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    showFoulsText: e.target.checked,
                  })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>
          </>
        )}
        {config.selectedMode != "RECEIVE" && (
          <>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Use ELAM
              </Typography>
              <Checkbox
                checked={config.useELAM}
                onChange={(e) =>
                  setConfig({ ...config, useELAM: e.target.checked })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>
            <Stack className="settingsRect">
              <TextField
                value={config.TargetIncrement}
                onChange={(e) => {
                  setConfig({ ...config, TargetIncrement: e.target.value });
                }}
                variant="standard"
                label="TGT Increment"
                size="small"
                sx={{ margin: "5px", color: "white", marginLeft: "10px" }}
                InputProps={{ sx: { color: "white" } }}
                InputLabelProps={{ sx: { color: "white" } }}
              />
            </Stack>
            <Stack className="settingsRect">
              <TextField
                value={config.TargetPreword}
                onChange={(e) => {
                  setConfig({ ...config, TargetPreword: e.target.value });
                }}
                variant="standard"
                label="TGT Preword"
                size="small"
                sx={{ margin: "5px", color: "white", marginLeft: "10px" }}
                InputProps={{ sx: { color: "white" } }}
                InputLabelProps={{ sx: { color: "white" } }}
              />
            </Stack>
            <Stack className="settingsRect">
              <InputMask
                mask="99:99"
                value={config.TargetTimeMark}
                disabled={false}
                maskChar="0"
                onChange={(e) => {
                  console.log(config);
                  setConfig({ ...config, TargetTimeMark: e.target.value });
                }}
              >
                {() => (
                  <TextField
                    variant="standard"
                    label="Time Mark"
                    value={config.TargetTimeMark}
                    sx={{ margin: "5px", color: "white", marginLeft: "10px" }}
                    InputProps={{ sx: { color: "white" } }}
                    InputLabelProps={{ sx: { color: "white" } }}
                  />
                )}
              </InputMask>
            </Stack>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Override ELAM
              </Typography>
              <Checkbox
                checked={config.overrideELAM}
                onChange={(e) =>
                  setConfig({ ...config, overrideELAM: e.target.checked })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Show ELAM
              </Typography>
              <Checkbox
                checked={config.overrideShowELAM}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    overrideShowELAM: e.target.checked,
                  })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>
            <Stack className="settingsRect">
              <TextField
                value={config.overrideTarget}
                onChange={(e) => {
                  setConfig({ ...config, overrideTarget: e.target.value });
                }}
                variant="standard"
                label="Override TGT"
                size="small"
                InputProps={{ sx: { color: "white" } }}
                InputLabelProps={{ sx: { color: "white" } }}
                sx={{ margin: "5px", marginLeft: "10px" }}
              />
            </Stack>
            <Stack
              className="settingsRect"
              alignItems="center"
              direction="row"
              justifyContent="space-between"
            >
              <Typography sx={{ color: "white", marginLeft: "8px" }}>
                Override Period
              </Typography>
              <Checkbox
                checked={config.isOverridePeriod}
                onChange={(e) =>
                  setConfig({
                    ...config,
                    isOverridePeriod: e.target.checked,
                  })
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24, color: "white" } }}
              />
            </Stack>
            <Stack className="settingsRect">
              <FormControl
                variant="standard"
                sx={{ color: "white", m: 1, minWidth: 120 }}
              >
                <Select
                  value={config.overridePeriod}
                  onChange={(e) => {
                    setConfig({
                      ...config,
                      overridePeriod: e.target.value,
                    });
                  }}
                  sx={{
                    ":before": { borderBottomColor: "white", color: "white" },
                    ":after": { borderBottomColor: "white" },
                    ".MuiSelect-icon": { color: "white" },
                    color: "white",
                  }}
                >
                  {periodList.map((period, i) => (
                    <MenuItem key={i} value={period}>
                      {getPeriodOrdinal(period)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </>
        )}

        <Stack className="settingsRect">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="Id-label" sx={{ color: "white" }}>
              Venue
            </InputLabel>
            <Select
              labelId="Id-label"
              value={selectedVenue}
              onChange={(e) => {
                setConfig({
                  ...config,
                  Venue: e.target.value,
                });
                setSelectedVenue(e.target.value);
              }}
              size="small"
              sx={{
                ":before": { borderBottomColor: "white", color: "white" },
                ":after": { borderBottomColor: "white" },
                ".MuiSelect-icon": { color: "white" },
                color: "white",
              }}
            >
              {VenueList.map((p, i) => (
                <MenuItem key={i} value={p.Venue}>
                  {p.Venue}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Button
          variant="contained"
          sx={{
            bgcolor: "#2b3382;",
            marginTop: "0.5rem",
            textTransform: "none",
          }}
          className="button_options"
          onClick={() => props.handleDialogClose(true, config)}
        >
          Update
        </Button>
      </Stack>
    </Stack>
  );
}
