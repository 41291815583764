class ScoreModel {
  constructor() {
    this.Time = "10:00";
    this.HomeScore = "0";
    this.HomeTOL = "0";
    this.HomeFouls = "0";
    this.AwayScore = "0";
    this.AwayTOL = "0";
    this.AwayFouls = "0";
    this.Period = "1";
    this.ShotClock = "0";
    this.RawString = "";
    this.IsELAM = false;
    this.TargetScore = 0;
    this.IsFinal = false;
    this.IsHalf = false;
    this.Venue = "";
    this.config = {};
    this.lastMainClockUpdate = null;
    this.previousMainClock = "";
    this.ELAMScanTime = 1000;
    this.ELAMIntervalId = null;
    this.localizedStrings = {};

    this.checkForFinal = this.checkForFinal.bind(this);
    this.textDecoder = new TextDecoder();
  }
  setVenue = (Venue) => {
    this.Venue = Venue;
  };
  setConfig = (config) => {
    this.config = config;
  };
  setLocalizedStrings = (localizedStrings) => {
    this.localizedStrings = localizedStrings;
  };
  Copy = (source) => {
    this.Time = source.Time;
    this.HomeScore = source.HomeScore;
    this.HomeTOL = source.HomeTOL;
    this.HomeFouls = source.HomeFouls;
    this.AwayScore = source.AwayScore;
    this.AwayTOL = source.AwayTOL;
    this.AwayFouls = source.AwayFouls;
    this.Period = source.Period;
    this.ShotClock = source.ShotClock;
    this.RawString = source.RawString;
    this.IsELAM = source.IsELAM;
    this.TargetScore = source.TargetScore;
    this.IsFinal = source.IsFinal;
    this.IsHalf = source.IsHalf;
  };
  // getPeriodOrdinal = (period) => {
  //   if (period == 1) return "1ST";
  //   else if (period == 2) return "2ND";
  //   else if (period == 3) return "3RD";
  //   else if (period == 4) return "4TH";
  //   else toString(period);
  // };
  formatNEVCOData = (rawData) => {
    var rawTime = "";
    this.HomeScore = rawData["home_score"];
    this.HomeFouls = rawData["home_fouls"];
    this.AwayScore = rawData["guest_score"];
    this.AwayFouls = rawData["guest_fouls"];
    this.Period = parseInt(rawData["period"]);
    this.HomeTOL = rawData["home_tol"];
    this.AwayTOL = rawData["guest_tol"];
    this.ShotClock = !this.IsFinal ? rawData["shot_clock"] : "";

    //if (this.IsFinal) this.Time = "FINAL";
    // else if (this.IsHalf) this.Time = "HALF";
    //else
    {
      var Sec = rawData["seconds"];
      var Min = rawData["minutes"];
      var Fractional = rawData["tenths"];
      var intSec = parseInt(Sec);
      var doubleSec = parseFloat(Sec);
      if (!isNaN(intSec)) {
        if (parseInt(Min) > 0)
          rawTime = this.formatTime(
            Min + ":" + intSec.toString().padStart(2, "0")
          );
        else {
          if (Fractional != "")
            rawTime = this.formatTime(Sec + "." + Fractional);
          else rawTime = Sec;
        }
      } else if (!isNaN(doubleSec)) {
        rawTime = this.formatTime(doubleSec.toString());
      }
    }

    this.checkForELAM(true);
    this.checkForFinal(rawTime);
    if (!this.IsFinal) this.checkForHalf(rawTime);
  };

  Utf8ArrayToStr(array) {
    var out, i, len, c;
    var char2, char3;

    out = "";
    len = array.length;
    i = 0;
    while (i < len) {
      c = array[i++];
      switch (c >> 4) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
        case 7:
          // 0xxxxxxx
          out += String.fromCharCode(c);
          break;
        case 12:
        case 13:
          // 110x xxxx   10xx xxxx
          char2 = array[i++];
          out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
          break;
        case 14:
          // 1110 xxxx  10xx xxxx  10xx xxxx
          char2 = array[i++];
          char3 = array[i++];
          out += String.fromCharCode(
            ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
          );
          break;
      }
    }

    return out;
  }

  formatDSTI_Wireless = (rawData) => {
    // console.log(rawData);
    var rawTime = "";
    // var usefulString = rawData;
    var usefulString = this.textDecoder.decode(
      Uint8Array.from(rawData.slice(0, 29))
    );
    if (usefulString) {
      //if (this.IsFinal) this.Time = "FINAL";
      // else if (this.IsHalf) this.Time = "HALF";
      //else
      {
        var timeString = usefulString.slice(0, 7);
        if (timeString.includes(".")) {
          var timeLines = timeString.split(":");
          if (timeLines.length > 1) {
            if (timeLines[1][0] == "0")
              rawTime = this.formatTime(timeLines[1].slice(1).trim());
            else rawTime = this.formatTime(timeLines[1].trim());
          } else rawTime = this.formatTime(timeString.replace(/^0+/, ""));
        } else rawTime = this.formatTime(timeString.trim());
      }

      this.ShotClock = !this.IsFinal ? usefulString.slice(8, 12).trim() : "";
      this.HomeScore = usefulString.slice(12, 15).trim();
      this.AwayScore = usefulString.slice(15, 18).trim();
      this.HomeFouls = usefulString.slice(18, 20).trim();
      this.AwayFouls = usefulString.slice(20, 22).trim();
      this.HomeTOL = usefulString.slice(24, 25).trim();
      this.AwayTOL = usefulString.slice(27, 28).trim();
      var period = parseInt(usefulString.slice(28, 29));
      var periodString = isNaN(period) ? "" : period;
      this.Period = this.config.isOverridePeriod
        ? this.config.overridePeriod
        : periodString;
      this.RawString = usefulString;

      this.checkForELAM();
      this.checkForFinal(rawTime);
      if (!this.IsFinal) this.checkForHalf(rawTime);
    }
  };

  formatDSTI_B = (rawData) => {
    // console.log(rawData);
    var rawTime = "";
    var usefulString = this.textDecoder.decode(
      Uint8Array.from(rawData.slice(0, 29))
    );
    if (usefulString) {
      //if (this.IsFinal) this.Time = "FINAL";
      // else if (this.IsHalf) this.Time = "HALF";
      //else
      {
        var timeString = usefulString.slice(0, 7);
        if (timeString.includes(".")) {
          var timeLines = timeString.split(":");
          if (timeLines.length > 1) {
            if (timeLines[1][0] == "0")
              rawTime = this.formatTime(timeLines[1].slice(1).trim());
            else rawTime = this.formatTime(timeLines[1].trim());
          } else rawTime = this.formatTime(timeString.replace(/^0+/, ""));
        } else rawTime = this.formatTime(timeString.trim());
      }

      if (!this.IsFinal) {
        var sclock = usefulString.slice(7, 10).trim();
        this.ShotClock = sclock.replace("s", "").trim();
      } else this.ShotClock = "";
      this.HomeScore = usefulString.slice(10, 13).trim();
      this.AwayScore = usefulString.slice(13, 16).trim();
      this.HomeFouls = usefulString.slice(16, 18).trim();
      this.AwayFouls = usefulString.slice(18, 20).trim();
      this.HomeTOL = usefulString.slice(20, 21).trim();
      this.AwayTOL = usefulString.slice(23, 24).trim();
      var period = parseInt(usefulString.slice(26, 27));
      var periodString = isNaN(period) ? "" : period;
      this.Period = this.config.isOverridePeriod
        ? this.config.overridePeriod
        : periodString;
      this.RawString = usefulString;

      this.checkForELAM();
      this.checkForFinal(rawTime);
      if (!this.IsFinal) this.checkForHalf(rawTime);
    }
  };

  _convertOESChar = (val) => {
    var time_min = val;
    if (time_min > 57) time_min = time_min - 128;

    return String.fromCharCode(time_min);
  };
  _isValidChar = (cVal) => {
    var c = cVal.charCodeAt(0);
    return c >= 48 && c <= 57;
  };

  _getNumericValue = (c) => {
    return String.fromCharCode(c);
  };
  formatOES = (rawString) => {
    var rawTime = "";
    if (rawString.length >= 18) {
      //if (this.IsFinal) this.Time = "FINAL";
      // else if (this.IsHalf) this.Time = "HALF";
      //else
      {
        var gt_min1 = this._convertOESChar(rawString[0]);
        var gt_min2 = this._convertOESChar(rawString[1]);
        var gt_sec1 = this._convertOESChar(rawString[2]);
        var gt_sec2 = this._convertOESChar(rawString[3]);

        if (
          this._isValidChar(gt_min1) &&
          this._isValidChar(gt_min2) &&
          this._isValidChar(gt_sec1) &&
          this._isValidChar(gt_sec2)
        )
          rawTime = this.formatTime(
            [gt_min1, gt_min2, ":", gt_sec1, gt_sec2].join("")
          );
        else if (
          this._isValidChar(gt_min2) &&
          this._isValidChar(gt_sec1) &&
          this._isValidChar(gt_sec2)
        )
          rawTime = this.formatTime([gt_min2, ":", gt_sec1, gt_sec2].join(""));
        else if (
          this._isValidChar(gt_min1) &&
          this._isValidChar(gt_min2) &&
          this._isValidChar(gt_sec1)
        )
          rawTime = this.formatTime([gt_min1, gt_min2, ".", gt_sec1].join(""));
        else if (this._isValidChar(gt_sec1) && this._isValidChar(gt_sec2))
          rawTime = this.formatTime([gt_sec1, gt_sec2].join(""));
        else if (this._isValidChar(gt_min1) && this._isValidChar(gt_min2))
          rawTime = this.formatTime([gt_min1, gt_min2].join(""));
        else if (this._isValidChar(gt_min2) && this._isValidChar(gt_sec1))
          rawTime = this.formatTime([gt_min2, ".", gt_sec1].join(""));
        else if (this._isValidChar(gt_min1)) rawTime = this.formatTime(gt_min1);
        else if (this._isValidChar(gt_min2)) rawTime = this.formatTime(gt_min2);
        else if (this._isValidChar(gt_sec1)) rawTime = this.formatTime(gt_sec1);
        else if (this._isValidChar(gt_sec2)) rawTime = this.formatTime(gt_sec2);
      }
      if (!isNaN(parseInt(rawString[4])))
        this.Period = this.config.isOverridePeriod
          ? this.config.overridePeriod
          : String.fromCharCode(rawString[4]);

      var hm1 = this._convertOESChar(rawString[5]);
      var hm2 = this._convertOESChar(rawString[6]);

      if (rawString[5] > 57 && rawString[6] > 57)
        this.HomeScore = ["1", hm1, hm2].join("");
      else if (
        rawString[5] >= 48 &&
        rawString[5] <= 57 &&
        rawString[6] >= 48 &&
        rawString[6] <= 57
      )
        this.HomeScore = [hm1, hm2].join("");
      else if (rawString[5] >= 48 && rawString[5] <= 57) this.HomeScore = hm1;
      else if (rawString[6] >= 48 && rawString[6] <= 57) this.HomeScore = hm2;

      var aw1 = this._convertOESChar(rawString[7]);
      var aw2 = this._convertOESChar(rawString[8]);

      if (rawString[7] > 57 && rawString[8] > 57)
        this.AwayScore = ["1", aw1, aw2].join("");
      else if (
        rawString[7] >= 48 &&
        rawString[7] <= 57 &&
        rawString[8] >= 48 &&
        rawString[8] <= 57
      )
        this.AwayScore = [aw1, aw2].join("");
      else if (rawString[7] >= 48 && rawString[7] <= 57) this.AwayScore = aw1;
      else if (rawString[8] >= 48 && rawString[8] <= 57) this.AwayScore = aw2;

      this.HomeTOL = this._convertOESChar(rawString[9]);
      this.AwayTOL = this._convertOESChar(rawString[10]);
      this.HomeFouls = this._convertOESChar(rawString[11]);
      this.AwayFouls = this._convertOESChar(rawString[12]);

      if (!this.IsFinal) {
        var sht_t1 = this._convertOESChar(rawString[16]);
        var sht_t2 = this._convertOESChar(rawString[17]);
        if (rawString[16] >= 48 && rawString[16] <= 57) {
          this.ShotClock = [
            this._getNumericValue(rawString[16]),
            this._getNumericValue(rawString[17]),
          ].join("");
        } else if (rawString[17] >= 49 && rawString[17] <= 57)
          this.ShotClock = this._getNumericValue(rawString[17]);
        else this.ShotClock = "";
      } else this.ShotClock = "";

      this.RawString = this.textDecoder.decode(Uint8Array.from(rawString));
    }

    this.checkForELAM();
    this.checkForFinal(rawTime);
    if (!this.IsFinal) this.checkForHalf(rawTime);
  };

  checkForELAM = (isNevco = false) => {
    if (this.previousMainClock != this.Time)
      this.lastMainClockUpdate = Date.now();
    this.previousMainClock = this.Time;

    if (this.config.overrideELAM) {
      this.IsELAM = this.config.overrideShowELAM;
      this.TargetScore = this.config.overrideTarget;
    } else if (
      this.config.useELAM &&
      !!this.Time &&
      this.config.selectedMode != "RECEIVE" &&
      this.config.selectedGame == "BASKETBALL"
    ) {
      var timeLines = this.Time.split(":");
      var sec = Number.MAX_SAFE_INTEGER;
      if (timeLines.length == 1) {
        sec = parseInt(timeLines[0]);
      } else if (timeLines.length == 2) {
        sec = parseInt(timeLines[0]) * 60 + parseInt(timeLines[1]);
      } else return;

      var setTimeLines = this.config.TargetTimeMark.split(":");
      var setSec = parseInt(setTimeLines[0]) * 60 + parseInt(setTimeLines[1]);

      if (!isNevco && this.IsELAM && sec >= setSec) this.IsELAM = false;
      if (!this.IsELAM) {
        if (
          this.Period == "4" ||
          this.Period == "4th" ||
          this.Period == "4TH"
        ) {
          if (sec < setSec)
            if (this.ELAMIntervalId == null)
              this.ELAMIntervalId = setInterval(() => {
                console.log("checking ELAM");
                if (Date.now() - this.lastMainClockUpdate > 2000) {
                  this.IsELAM = true;
                  var maxScore =
                    parseInt(this.HomeScore) > parseInt(this.AwayScore)
                      ? parseInt(this.HomeScore)
                      : parseInt(this.AwayScore);

                  this.TargetScore = (
                    maxScore + parseInt(this.config.TargetIncrement)
                  ).toString();

                  clearInterval(this.ELAMIntervalId);
                  this.ELAMIntervalId = null;
                }
              }, this.ELAMScanTime);
        }
      }
    }
  };

  checkForFinal = (rawTime) => {
    if (
      (this.IsELAM ||
        (!this.IsELAM && (rawTime.trim() == "" || rawTime == "FINAL"))) &&
      (this.Period == "4" || this.Period == "4th" || this.Period == "4TH")
    ) {
      var homeScore = parseInt(this.HomeScore);
      var awayScore = parseInt(this.AwayScore);
      var targetScore = parseInt(this.TargetScore);
      if (homeScore >= targetScore || awayScore >= targetScore) {
        // this.IsELAM = false;
        this.Time = "FINAL";
        this.IsFinal = true;
      } else this.Time = rawTime;
    } else {
      this.IsFinal = false;
      this.Time = rawTime;
    }
    // else {
    //   this.IsFinal = false;
    //   if (this.Time == "FINAL") this.Time = "";
    // }
  };
  checkForHalf = (rawTime) => {
    if (
      (rawTime.trim() == "" || rawTime == "HALF") &&
      (this.Period == "2" || this.Period == "2nd" || this.Period == "2ND")
    ) {
      this.Time = "HALF";
      this.IsHalf = true;
    } else {
      this.IsHalf = false;
      this.Time = rawTime;
    }
  };
  formatTime = (time) => {
    if (
      time == "0.0" ||
      time == "0" ||
      time == "00" ||
      time == "0:00" ||
      time == "0:0" ||
      time == "00:00" ||
      time == "0.0" ||
      time == "0.00" ||
      time == "00.0" ||
      time == ".0"
    )
      return "";
    else return time;
  };
  init = () => {
    this.IsELAM = false;
    this.IsFinal = false;
    this.IsHalf = false;
  };

  getData = () => {
    return {
      Time: this.Time,
      HomeScore: this.HomeScore,
      HomeTOL: this.HomeTOL,
      HomeFouls: this.HomeFouls,
      AwayScore: this.AwayScore,
      AwayTOL: this.AwayTOL,
      AwayFouls: this.AwayFouls,
      Period: this.Period,
      ShotClock: this.ShotClock,
      RawString: this.RawString,
      IsELAM: this.IsELAM,
      IsFinal: this.IsFinal,
      IsHalf: this.IsHalf,
      TargetScore: this.TargetScore,
      ID: this.ID,
    };
  };
}
export default ScoreModel;
