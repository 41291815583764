import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import React from "react";
import Moment from "moment";
import "./masterSchedule.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AlertDialog from "../Common/AlertDialog";

const Input = styled("input")({
  display: "none",
});

export default class MasterSchedule extends React.Component {
  constructor(props) {
    super(props);

    this.LEAGUE = "CEBL";

    this.state = {
      NewTeam: { ShortForm: "", FullForm: "" },
      Venue: "",
      NewLeague: {
        LeagueName: "",
        BugPNG:
          "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8PDw8PDw8VFRUVDxUPFRUPFRUPFRUVFRUWFhUVFRUYHSggGBolHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NDg0NDisZFRkrKys3Ny0rKysrNysrNysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAMIBAwMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQcC/8QAFhABAQEAAAAAAAAAAAAAAAAAAAER/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDERFAQAAAAAAACigioIqooAioqCoAoCKogCiKACAACACgqKg5FRQAABQQAAAABBQBQgKACCCoIogCgCgAACgAiACgoIICKAAKmCgigKigCGKgKAgAACKAACCoIogqqAgACACgCooIoIAqIKIIAooCKiooCKgAAKIoAACoAqACCoIAAAAKigAAKgAAKAAgqKgAKAIAAAqAAAoigAAAAVKoCAKEFEQABFBQBUVA0AAAAUQBAVAAAFQAAAABBUBVAAEUAAAAAAAAAFBAADAAAARcFEFRAAAAAAAAEAAFQFUAAIAIoACAqoAAoIAqACKAAAAgoCACAAoAIAAAKACCiAqgAAAAAAAoiggCgAAqQQAFUwBEQVAABAAABQAQAAAFFEVFAAAAAAAAAVQRUQFQBagUABQRUQAFQAAAQAAABQAQVAVUUAAAAAVFBFEUUEQAAAAAAQBUAAAAAAAEAAUFRQARFEUUAAVFBFAEAUAAAAAEQQFAAAAABAAUAEBUUUAAAAAAABQAFQAAUQAAAQqRQEhQAUARYAJQAWEAABAAFIAotQEBYAJVACigEAUf/2Q==",
      },
      Overlay: false,
      OverlayFixture: false,
      ShowStatus: false,
      NewTeamStatus: false,
      TeamList: [],
      FixtureList: [],
      IDList: [],
      VenueList: [],
      // LeagueList: [],
      // OneFixtureList: [],
      openForm: false,
      openList: false,
      search: "",
      openAlert: false,
      alertMessage: "",
      alertContextID: "",
      NewFixture: {
        LeagueName: this.LEAGUE,
        OwnID: "",
        Date: new Date(),
        Home: "",
        Away: "",
        Venue: "",
        BugPNG:
          "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8PDw8PDw8VFRUVDxUPFRUPFRUPFRUVFRUWFhUVFRUYHSggGBolHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NDg0NDisZFRkrKys3Ny0rKysrNysrNysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAMIBAwMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQcC/8QAFhABAQEAAAAAAAAAAAAAAAAAAAER/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDERFAQAAAAAAACigioIqooAioqCoAoCKogCiKACAACACgqKg5FRQAABQQAAAABBQBQgKACCCoIogCgCgAACgAiACgoIICKAAKmCgigKigCGKgKAgAACKAACCoIogqqAgACACgCooIoIAqIKIIAooCKiooCKgAAKIoAACoAqACCoIAAAAKigAAKgAAKAAgqKgAKAIAAAqAAAoigAAAAVKoCAKEFEQABFBQBUVA0AAAAUQBAVAAAFQAAAABBUBVAAEUAAAAAAAAAFBAADAAAARcFEFRAAAAAAAAEAAFQFUAAIAIoACAqoAAoIAqACKAAAAgoCACAAoAIAAAKACCiAqgAAAAAAAoiggCgAAqQQAFUwBEQVAABAAABQAQAAAFFEVFAAAAAAAAAVQRUQFQBagUABQRUQAFQAAAQAAABQAQVAVUUAAAAAVFBFEUUEQAAAAAAQBUAAAAAAAEAAUFRQARFEUUAAVFBFAEAUAAAAAEQQFAAAAABAAUAEBUUUAAAAAAABQAFQAAUQAAAQqRQEhQAUARYAJQAWEAABAAFIAotQEBYAJVACigEAUf/2Q==",
        ReceiveID: "",
        id: "",
      },
    };

    // Moment.tz.setDefault("Etc/GMT");
  }

  loadIDList = () => {
    fetch("/api/loadIDList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.text();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        this.setState({ IDList: JSON.parse(jsonData) });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadTeamList = () => {
    fetch("/api/loadTeamList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        this.setState({ TeamList: jsonData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadFixtureList = () => {
    fetch("/api/loadFixtureList?league=" + this.LEAGUE, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        console.log(jsonData);
        this.setState({ FixtureList: jsonData });
        // if (this.state.NewFixture.LeagueName) {
        //   this.setState({
        //     OneFixtureList: jsonData?.[this.state.NewFixture.LeagueName],
        //   });
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadVenueList = () => {
    fetch("/api/loadVenueList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        this.setState({ VenueList: jsonData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadLeagueList = () => {
    fetch("/api/loadLeagueList/", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        this.setState({ LeagueList: jsonData });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentDidMount = () => {
    this.loadIDList();
    this.loadTeamList();
    this.loadFixtureList();
    this.loadLeagueList();
    this.loadVenueList();
  };

  createTeam = () => {
    fetch("/api/createTeam", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.NewTeam),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        if (jsonData.status == true) {
          alert("Success! Successfully Created");
          this.loadTeamList();
          this.setState({ NewTeam: { ShortForm: "", FullForm: "" } });
        } else {
          alert("Failure: Team already exists.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  createORupdateFixture = () => {
    if (this.state.NewFixture?.id) {
      this.updateFixture();
    } else {
      this.createFixture();
    }
  };
  createOrUpdateLeague = () => {
    if (this.state.NewLeague?.ID) this.updateLeague();
    else this.createLeague();
  };

  initialLeague = () => {
    this.setState({
      Overlay: false,
      NewLeague: {
        LeagueName: "",
        BugPNG:
          "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8PDw8PDw8VFRUVDxUPFRUPFRUPFRUVFRUWFhUVFRUYHSggGBolHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NDg0NDisZFRkrKys3Ny0rKysrNysrNysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAMIBAwMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQcC/8QAFhABAQEAAAAAAAAAAAAAAAAAAAER/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDERFAQAAAAAAACigioIqooAioqCoAoCKogCiKACAACACgqKg5FRQAABQQAAAABBQBQgKACCCoIogCgCgAACgAiACgoIICKAAKmCgigKigCGKgKAgAACKAACCoIogqqAgACACgCooIoIAqIKIIAooCKiooCKgAAKIoAACoAqACCoIAAAAKigAAKgAAKAAgqKgAKAIAAAqAAAoigAAAAVKoCAKEFEQABFBQBUVA0AAAAUQBAVAAAFQAAAABBUBVAAEUAAAAAAAAAFBAADAAAARcFEFRAAAAAAAAEAAFQFUAAIAIoACAqoAAoIAqACKAAAAgoCACAAoAIAAAKACCiAqgAAAAAAAoiggCgAAqQQAFUwBEQVAABAAABQAQAAAFFEVFAAAAAAAAAVQRUQFQBagUABQRUQAFQAAAQAAABQAQVAVUUAAAAAVFBFEUUEQAAAAAAQBUAAAAAAAEAAUFRQARFEUUAAVFBFAEAUAAAAAEQQFAAAAABAAUAEBUUUAAAAAAABQAFQAAUQAAAQqRQEhQAUARYAJQAWEAABAAFIAotQEBYAJVACigEAUf/2Q==",
      },
    });
  };

  createLeague = () => {
    fetch("/api/createLeague", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.NewLeague),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        if (jsonData.status == true) {
          this.loadLeagueList();
        } else {
          alert("Failure: Already League Name exist");
        }
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong", err);
      });
    this.initialLeague();
  };

  updateLeague = () => {
    let id = this.state.NewLeague?.ID;
    fetch(`/api/updateLeague/${id}`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.NewLeague),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        this.loadLeagueList();
      })
      .catch((err) => {
        console.log(err);
      });
    this.initialLeague();
  };

  setNewFixtureDefault = () => {
    this.setState({
      openForm: false,
      NewFixture: {
        ...this.state.NewFixture,
        id: "",
        OwnID: "",
        Date: new Date(),
        Home: "",
        Away: "",
        Venue: "",
        BugPNG:
          "/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8PDw8PDw8VFRUVDxUPFRUPFRUPFRUVFRUWFhUVFRUYHSggGBolHRUVITEhJSkrLi4uFx8zODMtNygtLisBCgoKDQ0NDg0NDisZFRkrKys3Ny0rKysrNysrNysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAMIBAwMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAAAAQcC/8QAFhABAQEAAAAAAAAAAAAAAAAAAAER/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/xAAUEQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIRAxEAPwDERFAQAAAAAAACigioIqooAioqCoAoCKogCiKACAACACgqKg5FRQAABQQAAAABBQBQgKACCCoIogCgCgAACgAiACgoIICKAAKmCgigKigCGKgKAgAACKAACCoIogqqAgACACgCooIoIAqIKIIAooCKiooCKgAAKIoAACoAqACCoIAAAAKigAAKgAAKAAgqKgAKAIAAAqAAAoigAAAAVKoCAKEFEQABFBQBUVA0AAAAUQBAVAAAFQAAAABBUBVAAEUAAAAAAAAAFBAADAAAARcFEFRAAAAAAAAEAAFQFUAAIAIoACAqoAAoIAqACKAAAAgoCACAAoAIAAAKACCiAqgAAAAAAAoiggCgAAqQQAFUwBEQVAABAAABQAQAAAFFEVFAAAAAAAAAVQRUQFQBagUABQRUQAFQAAAQAAABQAQVAVUUAAAAAVFBFEUUEQAAAAAAQBUAAAAAAAEAAUFRQARFEUUAAVFBFAEAUAAAAAEQQFAAAAABAAUAEBUUUAAAAAAABQAFQAAUQAAAQqRQEhQAUARYAJQAWEAABAAFIAotQEBYAJVACigEAUf/2Q==",
        ReceiveID: "",
      },
    });
  };

  createFixture = () => {
    fetch("/api/createFixture", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...this.state.NewFixture }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        this.loadFixtureList();
      })
      .catch((err) => {
        console.log(err);
        alert("Something went wrong", err);
      });

    this.setNewFixtureDefault();
  };

  updateFixture = () => {
    let id = this.state.NewFixture?.id;
    let name = this.state.NewFixture?.LeagueName;
    fetch(`/api/updateFixture/${name}_${id}`, {
      method: "put",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.NewFixture),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        this.loadFixtureList();
      })
      .catch((err) => {
        console.log(err);
      });
    this.setNewFixtureDefault();
  };

  deleteFixture = (id) => {
    let name = this.state.NewFixture.LeagueName;
    fetch(`/api/deleteFixture/${name}_${id}`, {
      method: "delete",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        this.loadFixtureList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  createVenue = () => {
    fetch("/api/createVenue", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Venue: this.state.Venue }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        if (jsonData.status) {
          alert("Success! Successfully Created");
          this.setState({ notification: true });
          this.loadVenueList();
        } else {
          alert("Failure: Already Venue Exists");
        }
      })
      .catch((err) => {
        alert("Something went wrong", err);
      });
    this.setState({ Venue: "" });
  };

  arrayBufferToBase64 = (buffer) => {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  handleImage = (event) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      this.setState({
        NewFixture: {
          ...this.state.NewFixture,
          BugPNG: this.arrayBufferToBase64(e.target.result),
        },
      });
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  handleImageLeague = (event) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      this.setState({
        NewLeague: {
          ...this.state.NewLeague,
          BugPNG: this.arrayBufferToBase64(e.target.result),
        },
      });
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  openLeagueDetails = (LeagueName) => {
    this.setState({
      NewFixture: { ...this.state.NewFixture, LeagueName: LeagueName },
      OneFixtureList: this.state.FixtureList?.[LeagueName],
      OverlayFixture: true,
    });
  };

  closeLeagueDetails = () => {
    this.setState({
      NewFixture: { ...this.state.NewFixture, LeagueName: "" },
      OverlayFixture: false,
    });
  };

  editLeague = (id) => {
    let data = this.state.LeagueList;
    let res = data.find((item) => id == item?.ID);
    this.setState({ NewLeague: res, Overlay: true });
  };

  deleteLeague = (id) => {
    if (window.confirm("Are you sure! You want to delete League!") == true) {
      fetch(`/api/deleteLeague/${id}`, {
        method: "delete",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) return response.json();
          else throw Error(response);
        })
        .then((jsonData) => {
          // this.loadLeagueList();
          this.loadFixtureList();
        })
        .catch((err) => {
          alert(err);
          console.log(err);
        });
    } else {
      return;
    }
  };

  onClickFixture = (LeagueName) => {
    let data = this.state.FixtureList?.[LeagueName];
    this.setState({
      NewFixture: { ...this.state.NewFixture, LeagueName: LeagueName },
      OverlayFixture: true,
      OneFixtureList: data,
    });
  };

  render() {
    const handleDelete = (id) => {
      this.setState({ openAlert: true });
    };

    const handleEdit = (id) => {
      let data = this.state.FixtureList;
      let res = data.find((item) => id == item?.id);
      console.log(res);
      this.setState({ NewFixture: res, openForm: true });
    };

    const FixtureElement = this.state.FixtureList?.sort((a, b) => {
      return new Date(a.Date) - new Date(b.Date);
    })
      .reverse()
      .map((item, i) => (
        <Paper key={i} className="fixture_paper">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="end"
            style={{ marginRight: "3rem" }}
          >
            <span
              className="btn_icon btn_edit_icon"
              onClick={() => handleEdit(item.id)}
            >
              <EditIcon />
            </span>
            <span
              className="btn_icon btn_del_icon"
              onClick={() =>
                this.setState({
                  openAlert: true,
                  alertMessage: `Do you want to confirm the deletion of game: ${
                    item.Home
                  } vs ${item.Away} dated: ${Moment(item.Date).format(
                    "DD MMM YYYY HH:mm"
                  )} Venue: ${item.Venue}`,
                  alertContextID: item.id,
                })
              }
            >
              <DeleteIcon />
            </span>
          </Stack>

          <Stack
            direction="row"
            style={{
              alignItems: "center",
              display: "grid",
              gridTemplateColumns: "1fr 0.5fr 0.5fr 0.5fr 1fr",
              justifyContent: "center",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingBottom: "0.5rem",
            }}
          >
            <h2>
              Venue : <strong>{item.Venue}</strong>
            </h2>
            <h1 style={{ textAlign: "right", marginRight: "0.5rem" }}>
              {item.Home}
            </h1>

            <Stack direction="column" spacing={1}>
              <span style={{ textAlign: "center", fontSize: "24px" }}>
                {Moment(item.Date).format("DD MMM YYYY HH:mm")}
              </span>

              <img
                width="400"
                height="170"
                src={`data:image/png;base64,${item.BugPNG}`}
              />
            </Stack>
            <h1 style={{ marginLeft: "0.5rem" }}>{item.Away}</h1>
          </Stack>
        </Paper>
      ));

    const listItems = this.state.LeagueList?.map((item, i) => (
      <Paper className="grid-item-leagues" key={i} id={i}>
        <Stack direction="column" spacing={1}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={1}
          >
            <EditIcon
              size="small"
              className="btn_icon btn_edit_icon"
              onClick={() => this.editLeague(item.ID)}
            />
            <DeleteIcon
              size="small"
              className="btn_icon btn_del_icon"
              onClick={() => this.deleteLeague(item.ID)}
            />
          </Stack>
          <Stack
            onClick={() => this.onClickFixture(item.LeagueName)}
            style={{ cursor: "pointer" }}
            direction="row"
            alignItems="center"
            spacing={1}
          >
            <img
              width="50px"
              height="50px"
              style={{ borderRadius: "50%" }}
              src={`data:image/png;base64,${item.BugPNG}`}
              alt={item.LeagueName}
            ></img>
            <Typography className="btn_icon btn_text">
              {item.LeagueName}
            </Typography>
          </Stack>
        </Stack>
      </Paper>
    ));

    return (
      <Stack spacing={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-around"
          className="teams"
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              sx={{ width: 200 }}
              style={{ justifySelf: "flex-end" }}
              value={this.state.NewTeam.ShortForm}
              onChange={(e) => {
                this.setState({
                  ShowStatus: false,
                  NewTeam: {
                    ...this.state.NewTeam,
                    ShortForm: e.target.value,
                  },
                });
              }}
              variant="standard"
              label="SHORT NAME"
            />

            <TextField
              sx={{ width: 200 }}
              style={{ justifySelf: "flex-end" }}
              value={this.state.NewTeam.FullForm}
              onChange={(e) => {
                this.setState({
                  ShowStatus: false,
                  NewTeam: {
                    ...this.state.NewTeam,
                    FullForm: e.target.value,
                  },
                });
              }}
              variant="standard"
              label="FULL NAME"
            />

            <Button
              disabled={
                !this.state.NewTeam.ShortForm || !this.state.NewTeam.FullForm
              }
              onClick={() => {
                this.createTeam();
              }}
              style={{ marginLeft: "10px", color: "white" }}
              variant="contained"
              size="large"
            >
              CREATE TEAM
            </Button>

            {/* {this.state.ShowStatus && this.state.NewTeamStatus && (
                <span style={{ color: "green", marginLeft: "20px" }}>
                  !! Team {this.state.NewTeam.FullForm} Creation Successful !!
                </span>
              )}
              {this.state.ShowStatus && !this.state.NewTeamStatus && (
                <span style={{ color: "red", marginLeft: "20px" }}>
                  !! Team {this.state.NewTeam.FullForm} Already Exists !!
                </span>
              )} */}
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              sx={{ width: 200 }}
              style={{ justifySelf: "flex-end" }}
              value={this.state.Venue}
              onChange={(e) => {
                this.setState({
                  Venue: e.target.value,
                });
              }}
              variant="standard"
              label="VENUE"
            />

            <Button
              disabled={!this.state.Venue}
              onClick={() => {
                this.createVenue();
              }}
              size="large"
              style={{ color: "white" }}
              variant="contained"
            >
              ADD VENUE
            </Button>
          </Stack>
        </Stack>

        <div
          style={{
            justifyItems: "right",
            display: "grid",
            gridTemplateColumns: "auto auto",
          }}
        >
          <h1>CEBL SCHEDULE</h1>
          <Button
            onClick={() => this.setState({ openForm: true })}
            style={{ width: "350px", alignSelf: "center" }}
            variant="contained"
            size="large"
            startIcon={<AddIcon />}
          >
            Add Game
          </Button>
        </div>
        {/* <div className="grid-container">
          <div className="grid-item">
            <Stack direction="row" alignItems="center" justifyContent="">
              <Typography style={{ fontSize: "28px" }}>All Leagues</Typography>
              <Button
                onClick={() => this.setState({ Overlay: true })}
                style={{ marginLeft: "1rem" }}
                variant="contained"
                size="small"
              >
                Add League
              </Button>
            </Stack> */}
        {/* {this.state.Overlay && (
              <Stack className="overlay-content" spacing={1}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className="border-bottom-black"
                >
                  <Typography color="primary">
                    {this.state.NewLeague?.ID ? "Edit" : "Add"} League
                  </Typography>
                  <span style={{ textAlign: "right" }} id="overlay_c">
                    <CancelOutlinedIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => this.initialLeague()}
                    ></CancelOutlinedIcon>
                  </span>
                </Stack>
                <Stack>
                  <Stack spacing={1} className="league-form">
                    <Stack spacing={1}>
                      <span>Logo</span>
                      <Stack direction="row" spacing={1}>
                        <img
                          width="100"
                          height="50"
                          src={`data:image/png;base64,${this.state.NewLeague.BugPNG}`}
                        />

                        <label htmlFor="contained-button-file">
                          <Input
                            accept="image/*"
                            id="contained-button-file"
                            type="file"
                            onChange={(event) => this.handleImageLeague(event)}
                          />
                          <IconButton
                            color="primary"
                            aria-label="upload picture"
                            component="span"
                          >
                            <WallpaperIcon />
                          </IconButton>
                        </label>
                      </Stack>
                    </Stack>

                    <TextField
                      disabled={this.state.NewLeague?.ID != undefined}
                      label="Name"
                      size="small"
                      variant="standard"
                      color="warning"
                      sx={{
                        svg: { color: "black" },
                        input: { color: "black" },
                        label: { color: "black", borderColor: "black" },
                      }}
                      value={this.state.NewLeague.LeagueName}
                      onChange={(e) => {
                        this.setState({
                          NewLeague: {
                            ...this.state.NewLeague,
                            LeagueName: e.target.value,
                          },
                        });
                      }}
                    ></TextField>
                    <Button
                      disabled={
                        !this.state.NewLeague.BugPNG ||
                        !this.state.NewLeague.LeagueName
                      }
                      onClick={() => {
                        this.createOrUpdateLeague();
                      }}
                      style={{ color: "white" }}
                      variant="contained"
                      color="warning"
                    >
                      {this.state.NewLeague?.ID
                        ? "UPDATE LEAGUE"
                        : "CREATE LEAGUE"}
                    </Button>
                  </Stack>
                </Stack>
              </Stack>
            )} */}

        {/* {this.state.LeagueList && this.state.LeagueList?.length > 0 ? (
              <>
                <div className="grid-container-leagues">{listItems}</div>
              </>
            ) : (
              <p>No Leagues Found</p>
            )} 
          </div>
        </div>*/}

        {this.state.openForm && (
          <Stack className="game-content">
            <Stack
              className="border-bottom"
              alignItems="center"
              justifyContent="center"
            >
              <Typography color="primary">
                {this.state.NewFixture?.id ? "EDIT" : "ADD"} GAME{" "}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={2} style={{ marginTop: "1rem" }}>
              {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="ownId-label" sx={{ color: "white" }}>
                          OWN ID
                        </InputLabel>
                        <Select
                          labelId="ownId-label"
                          value={this.state.NewFixture.OwnID}
                          onChange={(e) => {
                            this.setState({
                              NewFixture: {
                                ...this.state.NewFixture,
                                OwnID: e.target.value,
                              },
                            });
                          }}
                          color="warning"
                          sx={{
                            ":before": { borderBottomColor: "white", color: "white" },
                            ":after": { borderBottomColor: "white" },
                            ".MuiSelect-icon": { color: "white" },
                            color: "white",
                          }}
                        >
                          {this.state.IDList.map((p, i) => (
                            <MenuItem key={i} value={p.ID}>
                              {p.ID}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="home-label" sx={{ color: "white" }}>
                  HOME
                </InputLabel>
                <Select
                  labelId="home-label"
                  value={this.state.NewFixture.Home}
                  onChange={(e) => {
                    this.setState({
                      NewFixture: {
                        ...this.state.NewFixture,
                        Home: e.target.value,
                      },
                    });
                  }}
                  color="warning"
                  sx={{
                    ":before": {
                      borderBottomColor: "white",
                      color: "white",
                    },
                    ":after": { borderBottomColor: "white" },
                    ".MuiSelect-icon": { color: "white" },
                    color: "white",
                  }}
                >
                  {this.state.TeamList.map((p, i) => (
                    <MenuItem key={i} value={p.ShortForm}>
                      {p.FullForm}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="home-label" sx={{ color: "white" }}>
                  AWAY
                </InputLabel>
                <Select
                  labelId="home-label"
                  value={this.state.NewFixture.Away}
                  onChange={(e) => {
                    this.setState({
                      NewFixture: {
                        ...this.state.NewFixture,
                        Away: e.target.value,
                      },
                    });
                  }}
                  color="warning"
                  sx={{
                    ":before": {
                      borderBottomColor: "white",
                      color: "white",
                    },
                    ":after": { borderBottomColor: "white" },
                    ".MuiSelect-icon": { color: "white" },
                    color: "white",
                  }}
                >
                  {this.state.TeamList.map((p, i) => (
                    <MenuItem key={i} value={p.ShortForm}>
                      {p.FullForm}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="home-label" sx={{ color: "white" }}>
                  VENUE
                </InputLabel>
                <Select
                  labelId="home-label"
                  value={this.state.NewFixture.Venue}
                  onChange={(e) => {
                    this.setState({
                      NewFixture: {
                        ...this.state.NewFixture,
                        Venue: e.target.value,
                      },
                    });
                  }}
                  color="warning"
                  sx={{
                    ":before": {
                      borderBottomColor: "white",
                      color: "white",
                    },
                    ":after": { borderBottomColor: "white" },
                    ".MuiSelect-icon": { color: "white" },
                    color: "white",
                  }}
                >
                  {this.state.VenueList.map((p, i) => (
                    <MenuItem key={i} value={p.Venue}>
                      {p.Venue}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      sx={{
                        svg: { color: "white" },
                        input: { color: "white" },
                        label: { color: "white", borderColor: "white" },
                      }}
                    />
                  )}
                  color="warning"
                  label="DateTimePicker"
                  value={this.state.NewFixture.Date}
                  onChange={(newValue) => {
                    this.setState({
                      NewFixture: {
                        ...this.state.NewFixture,
                        Date: Moment(newValue).format("yyyy-MM-DD kk:mm:ss"),
                      },
                    });
                  }}
                  sx={{ borderColor: "white" }}
                />
              </LocalizationProvider>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
                spacing={1}
              >
                <Typography>PNG</Typography>
                <img
                  width="100"
                  height="50"
                  src={`data:image/png;base64,${this.state.NewFixture.BugPNG}`}
                />

                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    type="file"
                    onChange={(event) => this.handleImage(event)}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <WallpaperIcon />
                  </IconButton>
                </label>
              </Stack>
              <Button
                disabled={
                  !this.state.NewFixture.Date ||
                  !this.state.NewFixture.Home ||
                  !this.state.NewFixture.Away ||
                  !this.state.NewFixture.BugPNG ||
                  !this.state.NewFixture.Venue ||
                  !this.LEAGUE
                }
                onClick={() => {
                  this.createORupdateFixture();
                }}
                style={{ color: "white" }}
                variant="outlined"
              >
                {this.state.NewFixture?.id ? "UPDATE GAME" : "CREATE GAME"}
              </Button>
              <Button
                style={{ color: "white" }}
                variant="outlined"
                onClick={this.setNewFixtureDefault}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        )}

        {this.state.FixtureList && this.state.FixtureList.length > 0 ? (
          <div className="fixture_container">{FixtureElement}</div>
        ) : (
          <Typography color="error" textAlign="center">
            <strong>NO GAMES SCHEDULED</strong>
          </Typography>
        )}
        <AlertDialog
          open={this.state.openAlert}
          message={this.state.alertMessage}
          messageTitle={"Confirm Game Deletion?"}
          confirmAlert={(status) => {
            if (status) this.deleteFixture(this.state.alertContextID);
            this.setState({
              openAlert: false,
              alertMessage: "",
              alertContextID: "",
            });
          }}
        />
        {/* {this.state.OverlayFixture && (
          <div id="overlay">
            <Stack className="league-details">
              <Stack
                direction="row"
                justifyContent="space-around"
                alignItems="center"
                className="leagues-header"
              >
                <Stack direction="row" alignItems="center">
                  <Button onClick={() => this.closeLeagueDetails()}>
                    <ArrowBack />
                    Back
                  </Button>
                </Stack>
                <Typography color="primary" style={{ marginBottom: "0.5rem" }}>
                  {this.state.NewFixture.LeagueName} League Details
                </Typography>
                <Button
                  onClick={() => this.setState({ openForm: true })}
                  style={{ marginLeft: "1rem" }}
                  variant="contained"
                  size="small"
                >
                  Add Game
                </Button>
              </Stack>
            </Stack>
          </div>
        )} */}
      </Stack>
    );
  }
}
