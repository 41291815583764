import React, { useEffect, useState } from "react";
import Receive_Edit from "../Receive/Receive-Edit";
import { Card } from "@mui/material";

class MasterTemplate extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <Receive_Edit
        ComputerID={this.props.ComputerID}
        selectedLanguage={this.props.selectedLanguage}
        IsMasterTemplate={true}
      />
    );
  }
}
export default MasterTemplate;
