import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
  Card,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  FormControl,
  Select,
  Paper,
  MenuItem,
  InputLabel,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import "./Target.css";

const TargetSetting = (props) => {
  const [target, setTarget] = useState(0);
  const [isOverrideTarget, setIsOverrideTarget] = useState(false);
  // const [isShowTarget, setIsShowTarget] = useState(false);
  const [config, setConfig] = useState({});
  const [scoreObj, setScoreObj] = useState({});
  const [suggestedTarget, setSuggestedTarget] = useState(0);

  const sleep = (n) => new Promise((res) => setTimeout(res, n));

  const loadScore = async () => {
    var selectedID = sessionStorage.getItem("teamID");
    if (selectedID)
      while (true) {
        try {
          var result = await fetch("/api/getjson?id=" + selectedID, {
            method: "get",
            headers: { "Content-Type": "application/json" },
          });
          var jsonResult = await result.json();
          setScoreObj(jsonResult);

          await sleep(2000);
        } catch (error) {}
      }
  };

  // const scorePollTimer = setInterval(() => {
  //   var selectedID = sessionStorage.getItem("teamID");
  //   if (selectedID)
  //     fetch("/api/getjson?id=" + selectedID, {
  //       method: "get",
  //       headers: { "Content-Type": "application/json" },
  //     })
  //       .then((response) => {
  //         if (response.ok) return response.json();
  //         else throw Error(response);
  //       })
  //       .then((jsonData) => {
  //         // console.log(jsonData);
  //         setScoreObj(jsonData);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  // }, 10000);

  const loadConfig = () => {
    var selectedID = sessionStorage.getItem("teamID");
    if (selectedID)
      fetch("/api/loadConfig?id=" + selectedID, {
        method: "get",
        headers: { "Content-Type": "application/json" },
      })
        .then((response) => {
          if (response.ok) return response.json();
          else throw Error(response);
        })
        .then((jsonData) => {
          console.log(jsonData);
          setConfig(jsonData);
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const updateConfig = async (isOverrideTarget, target) => {
    var selectedID = sessionStorage.getItem("teamID");
    if (selectedID) {
      fetch("/api/updateConfig?id=" + selectedID, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          overrideELAM: isOverrideTarget,
          overrideShowELAM: isOverrideTarget,
          overrideTarget: target,
        }),
      });
    }
  };

  const loadData = () => {
    // setSelectedID(sessionStorage.getItem("teamID"));
    // setSelectedVenue(sessionStorage.getItem("teamVenue"));
    loadConfig();
    loadScore();
  };
  const getSuggestedTarget = () => {
    var maxScore =
      parseInt(scoreObj.HomeScore) > parseInt(scoreObj.AwayScore)
        ? parseInt(scoreObj.HomeScore)
        : parseInt(scoreObj.AwayScore);

    setSuggestedTarget(
      (maxScore + parseInt(config.TargetIncrement)).toString()
    );
  };
  useEffect(() => {
    loadData();
  }, []);
  useEffect(() => {
    getSuggestedTarget();
  }, [scoreObj]);

  useEffect(() => {
    setIsOverrideTarget(config["overrideELAM"]);
    // setIsShowTarget(config["overrideShowELAM"]);
    setTarget(config["overrideTarget"]);
  }, [config]);
  return (
    <Stack className="largeFont">
      <Typography sx={{ color: "white", margin: "5px", fontSize: "2rem" }}>
        Target Settings
      </Typography>
      <Typography sx={{ color: "white", margin: "5px", textAlign: "center" }}>
        Venue:{sessionStorage.getItem("teamVenue")} ID:
        {sessionStorage.getItem("teamID")}
      </Typography>
      <Stack spacing={1} sx={{ marginTop: "10px", margin: "10px" }}>
        <Paper variant="elevation" elevation={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography style={{ textAlign: "center", fontSize: "2rem" }}>
                Main Clock: {scoreObj.Time}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "center", fontSize: "2rem" }}>
                Home: {scoreObj.HomeScore}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ textAlign: "center", fontSize: "2rem" }}>
                Away: {scoreObj.AwayScore}
              </Typography>
            </Grid>
          </Grid>
        </Paper>

        <Paper variant="elevation" elevation={2}>
          <Stack spacing={1}>
            <Typography
              style={{
                textAlign: "center",
                marginLeft: "10px",
                color: "gray",
              }}
            >
              Suggested Target: {suggestedTarget}
            </Typography>
            {/* <FormControlLabel
              label="Override Target"
              sx={{ alignSelf: "center" }}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={isOverrideTarget}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 42 } }}
                  onClick={(e) => {
                    setIsOverrideTarget(e.target.checked);
                  }}
                />
              }
            /> */}

            <Stack
              spacing={2}
              sx={{ p: "2rem", justifyContent: "center" }}
              direction="row"
              flexWrap={"wrap"}
            >
              <FormControlLabel
                sx={{ alignSelf: "center" }}
                labelPlacement="start"
                control={
                  <Checkbox
                    checked={isOverrideTarget}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 42 } }}
                    onClick={(e) => {
                      setIsOverrideTarget(e.target.checked);
                      updateConfig(e.target.checked, target);
                    }}
                  />
                }
              />
              <Typography sx={{ textAlign: "center" }}>TARGET</Typography>
              <TextField
                variant="standard"
                placeholder="Target"
                sx={{
                  input: {
                    fontSize: "2rem",
                    width: "100px",
                  },
                }}
                value={target}
                onChange={(e) => {
                  setTarget(e.target.value);
                }}
              />
            </Stack>

            {/* <Button
              onClick={(e) => {
                updateConfig();
              }}
              variant="contained"
            >
              Apply
            </Button> */}
          </Stack>
        </Paper>
      </Stack>
    </Stack>
  );
};

export default TargetSetting;
