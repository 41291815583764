import LocalizedStrings from "react-localization";

let localizedStrings = new LocalizedStrings({
  en: {
    primary_server: "Primary Server",
    transmitting: "Transmitting",
    receiving: "Receiving",
    backup_server: "Backup Server",
    _1st: "1st",
    _2nd: "2nd",
    _3rd: "3rd",
    _4th: "4th",
    _1ST: "1ST",
    _2ND: "2ND",
    _3RD: "3RD",
    _4TH: "4TH",
    tgt: "TGT",
    fouls: "FOULS",
    penalty: "PENALTY",
    bonus: "BONUS",
    half: "HALF",
    final: "FINAL",
  },
  fr: {
    primary_server: "Primaire Server",
    transmitting: "transmettre",
    receiving: "Recevoir",
    backup_server: "Sauvegarde Server",
    _1st: "1er",
    _2nd: "2e",
    _3rd: "3e",
    _4th: "4e",
    _1ST: "1er",
    _2ND: "2e",
    _3RD: "3e",
    _4TH: "4e",
    tgt: "PC",
    fouls: "FAUTES",
    penalty: "PÉNALITÉ",
    bonus: "BONUS",
    half: "MI-TEMPS",
    final: "FIN",
  },
});

export default localizedStrings;
