import React from "react";
import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { red, purple } from "@mui/material/colors";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
  palette: {
    primary: {
      main: "#9147ff",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    fontFamily: `"Century Gothic", "CenturyGothic", "AppleGothic", "sans-serif"`,
  },
});

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
