import Board from "..//../components/Common/Board";
import React from "react";
import ScoreModel from "..//../Models//ScoreModel";

class DSTI_Wireless extends React.Component {
  constructor(props) {
    super(props);
    this.scoreObj = props.scoreObj;
    this.state = {
      intervalId: {},
      score: props.scoreObj,
      reader: null,
    };
    this.boardRef = {};
    this.interval = 300;
    this.serialProps = {
      baudRate: 9600,
      dataBits: 8,
      stopBits: 1,
      parity: "none",
    };
    this.keepReading = false;
    this.reader = null;
  }
  componentDidMount() {
    this.props.biRef.startAction = this.startAction;
    this.props.biRef.stopAction = this.stopAction;
  }
  connect = async () => {
    console.log("opening port");
    const ports = await navigator.serial.getPorts();
    var port = {};
    if (ports.length == 0) {
      var port = await navigator.serial.requestPort();
    } else port = ports[0];
    console.log(port.getInfo());
    await port.open(this.serialProps);

    this.port = port;
    this.keepReading = true;

    this.readData();
  };
  getValidData = (dataArray) => {
    var startIndex = -1;
    var endIndex = -1;
    for (var i = 0; i < dataArray.length; i++) {
      // console.log(dataArray[i]);
      if (dataArray[i] == 1) {
        startIndex = i;
      }
      if (dataArray[i] == 4 && (i - startIndex == 37 || i - startIndex == 32)) {
        endIndex = i;
      }
      if (startIndex > 0 && endIndex > 0 && endIndex > startIndex) break;
    }

    return { s: startIndex, e: endIndex };
  };
  getValidData_B = (dataArray) => {
    var startIndex = -1;
    var endIndex = -1;
    for (var i = 0; i < dataArray.length; i++) {
      // console.log(dataArray[i]);
      if (dataArray[i] == 1) {
        startIndex = i;
      }
      if (dataArray[i] == 4 && i - startIndex == 30) {
        endIndex = i;
      }
      if (startIndex > 0 && endIndex > 0 && endIndex > startIndex) break;
    }

    return { s: startIndex, e: endIndex };
  };
  readData = async () => {
    while (this.port.readable && this.keepReading) {
      this.reader = this.port.readable.getReader();
      try {
        var dataArray = [];
        while (true) {
          const { value, done } = await this.reader.read();
          if (done) {
            // reader.cancel() has been called.
            break;
          }
          dataArray.push(...value);

          var dataIndex =
            this.props.selectedMode == "DSTI_B"
              ? this.getValidData_B(dataArray)
              : this.getValidData(dataArray);
          if (dataIndex.s >= 0 && dataIndex.e >= 0) {
            var validData = dataArray.slice(dataIndex.s + 1, dataIndex.e - 1);
            // console.log(validData.length);
            var flags = [
              this.scoreObj.IsHalf,
              this.scoreObj.IsFinal,
              this.scoreObj.IsELAM,
            ];

            if (this.props.selectedMode == "DSTI_B")
              this.scoreObj.formatDSTI_B(validData);
            else this.scoreObj.formatDSTI_Wireless(validData);

            var setFlags = [
              this.scoreObj.IsHalf,
              this.scoreObj.IsFinal,
              this.scoreObj.IsELAM,
            ];
            if (!flags.every((val, i) => val == setFlags[i]))
              this.props.setFlags(
                this.scoreObj.IsHalf,
                this.scoreObj.IsFinal,
                this.scoreObj.IsELAM
              );
            this.setState({ score: this.scoreObj });
            // console.log(this.scoreObj);
            this.props.setScoreObj(this.scoreObj);
            if (dataIndex.e < dataArray.length - 1)
              dataArray = dataArray.slice(dataIndex.e);
            else dataArray = [];
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.reader.releaseLock();
        this.reader = null;
      }
    }
    if (this.port.readable) {
      await this.port.close();
    }
  };
  close = async () => {
    this.keepReading = false;
    if (this.reader) {
      this.reader.cancel();
    }
  };

  startAction = () => {
    this.scoreObj.init();
    this.connect();
    this.boardRef.startTransmitting();
  };
  stopAction = () => {
    this.close();
    this.boardRef.stopTransmitting();
  };

  render() {
    return (
      <Board
        ComputerID={this.props.ComputerID}
        biRef={this.boardRef}
        score={this.state.score}
        selectedLanguage={this.props.selectedLanguage}
        setIsConnected={(status) => this.props.setIsConnected(status)}
        setIsReceived={(status) => this.props.setIsReceived(status)}
        setIsTransmitting={(status) => {
          if (status) {
            this.scoreObj.init();
            this.connect();
          } else {
            this.close();
          }
          this.props.setIsTransmitting(status);
        }}
        setConfig={(config) => this.props.setConfig(config)}
      />
    );
  }
}

export default DSTI_Wireless;
