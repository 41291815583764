import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState, useRef, useEffect } from "react";
import { styled } from "@mui/material/styles";
import "./Receive.css";
import { SketchPicker } from "react-color";
import ColorizeOutlinedIcon from "@mui/icons-material/ColorizeOutlined";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import localizedStrings from "../../localization/localizedStrings";
import { useCallback } from "react";
const Input = styled("input")({
  display: "none",
});

function DragElement(props) {
  const inputEl = useRef(null);
  const sizeObserver = useRef(null);
  useEffect(() => {
    const elmnt = inputEl.current;
    elmnt.style.left = props.data.X * window.screen.width + "px";
    elmnt.style.top = props.data.Y * window.screen.height + "px";
    elmnt.style.width = props.data.Width * window.screen.width + "px";
    elmnt.style.height = props.data.Height * window.screen.height + "px";
    elmnt.style.resize = "both";
    sizeObserver.current = new ResizeObserver(onResize);
    sizeObserver.current.observe(elmnt);

    return () => sizeObserver.current.unobserve(elmnt);
  }, [props.data]);
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;

    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    const elmnt = inputEl.current;
    // elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    // elmnt.style.left = elmnt.offsetLeft - pos1 + "px";

    props.data.Y =
      Math.round(((elmnt.offsetTop - pos2) / window.screen.height) * 10000) /
      10000;
    props.data.X =
      Math.round(((elmnt.offsetLeft - pos1) / window.screen.width) * 10000) /
      10000;

    elmnt.style.left = props.data.X * window.screen.width + "px";
    elmnt.style.top = props.data.Y * window.screen.height + "px";

    // console.log(props.data.Width);
    props.handlePositionChange(props.data, true);
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }

  function updatePosition(newX, newY, newWidth, newHeight) {
    const elmnt = inputEl.current;
    elmnt.style.left = newX * window.screen.width + "px";
    elmnt.style.top = newY * window.screen.height + "px";
    elmnt.style.width = newWidth * window.screen.width + "px";
    elmnt.style.height = newHeight * window.screen.height + "px";

    // console.log(window.screen.height);
  }
  function onResize() {
    const elmnt = inputEl.current;
    if (elmnt != null) {
      props.data.Width =
        Math.round(
          (parseFloat(elmnt.style.width) / window.screen.width) * 10000
        ) / 10000;
      props.data.Height =
        Math.round(
          (parseFloat(elmnt.style.height) / window.screen.height) * 10000
        ) / 10000;
      //console.log(props.data.Width);
      //props.handlePositionChange(props.data, false);
      console.log(props.data.Y);
      props.handleSizeChange(props.data);
    }
  }

  function getTextAlign() {
    if (props.data.TextAlign == 1) return "left";
    else if (props.data.TextAlign == 2) return "right";
    else if (props.data.TextAlign == 3) return "center";
    else if (props.data.TextAlign == 4) return "justify";
    else return "";
  }
  props.biRef.updatePosition = updatePosition;
  return (
    <div
      ref={inputEl}
      style={{
        borderColor: props.data.IsSelected && "dodgerblue",
        overflow: "hidden",
        backgroundColor: props.data.BackgroundColor,
      }}
      className="draggable-parent"
    >
      <div
        onMouseDown={dragMouseDown}
        style={{
          textAlign: getTextAlign(),
          alignSelf: "center",
          flex: "1",
          cursor: "move",
          overflow: "hidden",
        }}
        className="draggable"
      >
        <span
          style={{
            fontSize: props.data.FontSize,
            color: props.data.Color,
            fontWeight: props.data.Bold && "bold",
            fontFamily: props.data.FontFamily,
          }}
        >
          {props.data.Value ? props.data.Value : props.data.SampleText}
        </span>
      </div>
    </div>
  );
}
function TemplateManger(props) {
  const [TemplateList, setTemplateList] = useState([]);
  const [SelectedTemplate, setSelectedTemplate] = useState("");
  const [Name, setName] = useState("");
  const inputEl = useRef(null);

  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;

    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }
  const closeDragElement = () => {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const elementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    const elmnt = inputEl.current;
    // elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    // elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    console.log("moving");

    var y =
      Math.round(((elmnt.offsetTop - pos2) / window.screen.height) * 10000) /
      10000;
    var x =
      Math.round(((elmnt.offsetLeft - pos1) / window.screen.width) * 10000) /
      10000;

    elmnt.style.left = x * window.screen.width + "px";
    elmnt.style.top = y * window.screen.height + "px";
  };

  useEffect(() => loadTemplateList(), []);
  const loadTemplateList = (lastTemplateName) => {
    fetch("/api/getTemplateList", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        // console.log(jsonData);
        var templateList = JSON.parse(jsonData);
        setTemplateList(templateList);
        if (lastTemplateName)
          setSelectedTemplate(
            templateList.find((item) => item.Name == lastTemplateName)
          );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const setNewTemplate = () => {
    fetch("/api/getNewTemplate", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        var jsonObj = JSON.parse(jsonData);
        props.setBackColor(jsonObj["Color"]);
        props.setFieldList(jsonObj["FieldList"]);
        props.setBackImage(jsonObj["Scorebug"]);
        setName(jsonObj["Name"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveTemplate = () => {
    var jsonObj = JSON.stringify({
      Name: Name,
      Color: props.Color,
      Scorebug: props.Scorebug,
      FieldList: props.FieldList,
    });
    // console.log(jsonObj);
    fetch("/api/saveTemplate", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: jsonObj,
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        loadTemplateList(Name);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteTemplate = () => {
    fetch("/api/deleteTemplate", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ Name: Name }),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        setName("");
        props.setBackColor("white");
        props.setFieldList([]);
        props.setBackImage("");
        loadTemplateList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "grid",
        width: "250px",
        gap: "20px",
        backgroundColor: "#171f32",
        borderRadius: "15px",
        padding: "10px",
        position: "absolute",
        zIndex: 20,
        left: "300px",
      }}
      ref={inputEl}
    >
      <span
        onMouseDown={dragMouseDown}
        style={{
          fontWeight: "bold",
          textAlign: "center",
          cursor: "move",
          zIndex: 20,
        }}
      >
        Template Manager
      </span>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
        <InputLabel sx={{ color: "white" }} id="Id-label">
          Template List
        </InputLabel>
        <Select
          labelId="Id-label"
          value={SelectedTemplate}
          onChange={(e) => {
            //setName(e.target.value);
            setSelectedTemplate(e.target.value);
            setName(e.target.value.Name);
            props.setBackColor(e.target.value.Color);
            props.setFieldList(e.target.value.FieldList);
            props.setBackImage(e.target.value.Scorebug);
          }}
          sx={{
            ":before": { color: "white" },
            ":after": { color: "white" },
            ".MuiSelect-icon": { color: "white" },
            color: "white",
          }}
        >
          {TemplateList?.map((p, i) => (
            <MenuItem key={i} value={p}>
              {p.Name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        variant="standard"
        value={Name}
        onChange={(event) => setName(event.target.value)}
        label="Template Name"
        InputProps={{ sx: { color: "white" } }}
        InputLabelProps={{ sx: { color: "white" } }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Button
          onClick={(e) => saveTemplate()}
          variant="contained"
          sx={{ backgroundColor: "#21325b;" }}
        >
          Save
        </Button>
        <Button
          onClick={(e) => setNewTemplate()}
          variant="contained"
          sx={{ backgroundColor: "#21325b;" }}
        >
          New
        </Button>
        <Button
          onClick={(e) => deleteTemplate()}
          variant="contained"
          sx={{ backgroundColor: "#21325b;" }}
        >
          Delete
        </Button>
      </div>
    </div>
  );
}

function ElementProp(props) {
  const [color, setColor] = useState(props.data.Color);
  const [showClrPicker, setShowClrPicker] = useState(false);
  const [showClrPicker1, setShowClrPicker1] = useState(false);
  const inputEl = useRef(null);
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;

    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    const elmnt = inputEl.current;
    // elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    // elmnt.style.left = elmnt.offsetLeft - pos1 + "px";

    props.data.Y =
      Math.round(((elmnt.offsetTop - pos2) / window.screen.height) * 10000) /
      10000;
    props.data.X =
      Math.round(((elmnt.offsetLeft - pos1) / window.screen.width) * 10000) /
      10000;

    elmnt.style.left = props.data.X * window.screen.width + "px";
    elmnt.style.top = props.data.Y * window.screen.height + "px";
  }

  function closeDragElement() {
    /* stop moving when mouse button is released:*/
    document.onmouseup = null;
    document.onmousemove = null;
  }
  // console.log(props.data);
  return (
    <div
      style={{
        display: "grid",
        width: "250px",
        gap: "20px",
        backgroundColor: "#171f32",
        borderRadius: "15px",
        padding: "10px",
        position: "absolute",
        zIndex: 20,
      }}
      ref={inputEl}
    >
      <span
        onMouseDown={dragMouseDown}
        style={{
          fontWeight: "bold",
          textAlign: "center",
          cursor: "move",
          zIndex: 20,
        }}
      >
        {props.data.Name} Properties
      </span>
      <TextField
        variant="standard"
        value={props.data.FontFamily}
        onChange={(event) => {
          props.data.FontFamily = event.target.value;
          props.handleFieldChange(props.data);
        }}
        label="Font Family"
        InputProps={{ sx: { color: "white" } }}
        InputLabelProps={{ sx: { color: "white" } }}
      />
      <TextField
        variant="standard"
        value={props.data.FontSize}
        onChange={(event) => {
          if (!isNaN(parseInt(event.target.value))) {
            props.data.FontSize = parseInt(event.target.value);
            props.handleFieldChange(props.data);
          }
        }}
        label="Font Size"
        InputProps={{ sx: { color: "white" } }}
        InputLabelProps={{ sx: { color: "white" } }}
      />
      <FormControl variant="standard">
        <InputLabel id="font-label" sx={{ color: "white" }}>
          Font Alignment
        </InputLabel>
        <Select
          value={props.data.TextAlign}
          onChange={(event) => {
            props.data.TextAlign = event.target.value;
            props.handleFieldChange(props.data);
          }}
          labelId="font-label"
          sx={{
            ":before": { color: "white" },
            ":after": { color: "white" },
            ".MuiSelect-icon": { color: "white" },
            color: "white",
          }}
        >
          <MenuItem value={1}>Left</MenuItem>
          <MenuItem value={2}>Right</MenuItem>
          <MenuItem value={3}>Center</MenuItem>
          <MenuItem value={4}>Justify</MenuItem>
        </Select>
      </FormControl>

      <div className="panel1">
        <TextField
          variant="standard"
          label="Color"
          value={props.data.Color}
          onChange={(event) => {
            props.data.Color = event.target.value;
            props.handleFieldChange(props.data);
          }}
          InputProps={{ sx: { color: "white" } }}
          InputLabelProps={{ sx: { color: "white" } }}
        />
        <IconButton
          color="primary"
          onClick={() => setShowClrPicker1(!showClrPicker1)}
        >
          <div className="colorize"></div>
        </IconButton>

        {showClrPicker1 && (
          <div style={{ left: "250px", position: "absolute", zIndex: "2" }}>
            <div
              style={{
                top: "0px",
                bottom: "0px",
                left: "0px",
                right: "0px",
                position: "fixed",
              }}
              onClick={(_) => setShowClrPicker1(false)}
            />
            <SketchPicker
              color={color}
              onChange={(color) => {
                props.data.Color = color.hex;
                props.handleFieldChange(props.data);
              }}
            />
          </div>
        )}
      </div>
      <div className="panel1">
        <TextField
          variant="standard"
          label="Background Color"
          value={props.data.BackgroundColor}
          onChange={(event) => {
            props.data.BackgroundColor = event.target.value;
            props.handleFieldChange(props.data);
          }}
          InputProps={{ sx: { color: "white" } }}
          InputLabelProps={{ sx: { color: "white" } }}
        />
        <IconButton
          color="primary"
          onClick={() => setShowClrPicker(!showClrPicker)}
        >
          <div className="colorize"></div>
        </IconButton>

        {showClrPicker && (
          <div style={{ left: "250px", position: "absolute", zIndex: "2" }}>
            <div
              style={{
                top: "0px",
                bottom: "0px",
                left: "0px",
                right: "0px",
                position: "fixed",
              }}
              onClick={(_) => setShowClrPicker(false)}
            />
            <SketchPicker
              color={color}
              onChange={(color) => {
                props.data.BackgroundColor = color.hex;
                props.handleFieldChange(props.data);
              }}
            />
          </div>
        )}
      </div>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ color: "white" }}>Display Field</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.data.FieldON}
              onChange={(event) => {
                props.data.FieldON = event.target.checked;
                props.handleFieldChange(props.data);
                // console.log(props.data.FieldON);
              }}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 24 },
                color: "rgba(255,255,255,0.12156862745098039)",
              }}
            />
          }
        />
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography sx={{ color: "white" }}>Bold</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={props.data.Bold}
              onChange={(event) => {
                props.data.Bold = event.target.checked;
                props.handleFieldChange(props.data);
                // console.log(props.data.FieldON);
              }}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 24 },
                color: "rgba(255,255,255,0.12156862745098039)",
              }}
            />
          }
        />
      </Stack>
      <Stack direction="row" className="panel1" spacing={1}>
        <TextField
          variant="standard"
          value={props.data.X}
          onChange={(event) => {
            props.data.X = event.target.value;
            props.handleFieldChange(props.data);
          }}
          label="X"
          InputProps={{ sx: { color: "white" } }}
          InputLabelProps={{ sx: { color: "white" } }}
        />
        <TextField
          variant="standard"
          value={props.data.Y}
          onChange={(event) => {
            props.data.Y = event.target.value;
            props.handleFieldChange(props.data);
          }}
          label="Y"
          InputProps={{ sx: { color: "white" } }}
          InputLabelProps={{ sx: { color: "white" } }}
        />
      </Stack>
      <Stack direction="row" className="panel1" spacing={1}>
        <TextField
          variant="standard"
          value={props.data.Height}
          onChange={(event) => {
            props.data.Height = event.target.value;
            props.handleFieldChange(props.data);
          }}
          label="Height"
          InputProps={{ sx: { color: "white" } }}
          InputLabelProps={{ sx: { color: "white" } }}
        />
        <TextField
          variant="standard"
          value={props.data.Width}
          onChange={(event) => {
            props.data.Width = event.target.value;
            props.handleFieldChange(props.data);
          }}
          label="Width"
          InputProps={{ sx: { color: "white" } }}
          InputLabelProps={{ sx: { color: "white" } }}
        />
      </Stack>

      <Button
        onClick={(e) => props.saveFieldData()}
        variant="contained"
        sx={{ backgroundColor: "#21325b;" }}
      >
        Save All
      </Button>
    </div>
  );
}

function Receive_Edit(props) {
  const [selectedField, setSelectedField] = useState(null);
  const [showClrPicker, setShowClrPicker] = useState(false);
  const [backColor, setBackColor] = useState("white");
  const [backImage, setBackImage] = useState("");
  const [FieldList, setFieldList] = useState([]);
  const [refreshUI, setRefreshUI] = useState(false);
  const [worker, setWorker] = useState(null);

  const arrayBufferToBase64 = (buffer) => {
    var binary = "";
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };
  const handleImage = (event) => {
    console.log("handling image");
    const reader = new FileReader();
    reader.onload = async (e) => {
      setBackImage(arrayBufferToBase64(e.target.result));
    };
    reader.readAsArrayBuffer(event.target.files[0]);
  };

  const initialFetch = () => {
    var url =
      "/api/getScorebugLayout/?id=" +
      props.ComputerID +
      "&venue=" +
      props.venue +
      "&lang=" +
      props.selectedLanguage;
    if (props.IsMasterTemplate) {
      url = "/api/getMasterLayout/?lang=" + props.selectedLanguage;
    }

    fetch(url, {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        console.log(jsonData);
        setBackColor(jsonData["color"]);
        setFieldList(jsonData["template"]);
        setBackImage(jsonData["image"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const panFunction = (event) => {
    var selectedField = FieldList.find((field) => field.IsSelected);

    var cond =
      event.key == "ArrowRight" ||
      event.key == "ArrowLeft" ||
      event.key == "ArrowUp" ||
      event.key == "ArrowDown";
    if (selectedField && cond) {
      event.stopPropagation();
      if (event.key == "ArrowRight") selectedField.X += 0.0005;
      else if (event.key == "ArrowLeft") selectedField.X -= 0.0005;

      if (event.key == "ArrowUp") selectedField.Y -= 0.0005;
      else if (event.key == "ArrowDown") selectedField.Y += 0.0005;

      var objIndex = FieldList.findIndex((obj) => obj.Id == selectedField.Id);
      FieldList[objIndex] = { ...selectedField };

      biRef[objIndex].updatePosition(
        selectedField.X,
        selectedField.Y,
        selectedField.Width,
        selectedField.Height
      );
      setFieldList([...FieldList]);
      setSelectedField({ ...FieldList[objIndex] });
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", panFunction, false);
    if (!props.IsMasterTemplate) props.biRef.startReceiving = startReceiving;

    return () => {
      document.removeEventListener("keydown", panFunction, false);
    };
  }, [FieldList, selectedField]);
  useEffect(() => {
    if (!props.IsMasterTemplate) props.biRef.stopReceiving = stopReceiving;
  }, [worker]);

  useEffect(() => {
    initialFetch();
  }, [props.ComputerID, props.selectedLanguage]);

  const handleSetField = (index) => () => {
    if (!FieldList[index].IsSelected) {
      FieldList.forEach((p) => (p.IsSelected = false));
      FieldList[index].IsSelected = true;
      setSelectedField({ ...FieldList[index] });
    }
    // console.log("set selected field");
  };
  const getPeriodOrdinal = (period) => {
    //console.log(localizedStrings);
    if (period == "1")
      return props.isUppercasePeriod
        ? localizedStrings._1ST
        : localizedStrings._1st;
    else if (period == "2")
      return props.isUppercasePeriod
        ? localizedStrings._2ND
        : localizedStrings._2nd;
    else if (period == "3")
      return props.isUppercasePeriod
        ? localizedStrings._3RD
        : localizedStrings._3rd;
    else if (period == "4")
      return props.isUppercasePeriod
        ? localizedStrings._4TH
        : localizedStrings._4th;
  };
  const getFoulsString = (fouls) => {
    //console.log(localizedStrings);
    if (
      !isNaN(parseInt(fouls)) &&
      !isNaN(parseInt(props.config.noOfFoulsPerPenalty))
    ) {
      if (parseInt(fouls) >= parseInt(props.config.noOfFoulsPerPenalty)) {
        if (props.config.penaltyWord == "bonus") return localizedStrings.bonus;
        else return localizedStrings.penalty;
      } else {
        if (props.config.showFoulsText)
          return localizedStrings.fouls + ": " + fouls;
        else return fouls;
      }
    } else return "";
  };
  const getTargetScoreString = (targetScore) => {
    if (!isNaN(parseInt(targetScore))) {
      return localizedStrings.tgt + " " + targetScore;
    } else return "";
  };
  const startReceiving = () => {
    const worker = new Worker(
      new URL("./Receive-Worker_ws.js", import.meta.url)
    );

    if (worker) {
      worker.onmessage = (e) => {
        // let msg = JSON.parse(e.data)
        if (e.data["type"] == "score") {
          var score = e.data["data"];
          var newFieldList = [...FieldList];
          if (FieldList.length > 0) {
            newFieldList[0].Value = score.IsFinal
              ? localizedStrings.final
              : score.Time;
            newFieldList[1].Value = score.HomeScore;
            newFieldList[2].Value = score.AwayScore;
            newFieldList[3].Value = score.HomeTOL;
            newFieldList[4].Value = score.AwayTOL;
            newFieldList[5].Value = getFoulsString(score.HomeFouls);
            newFieldList[6].Value = getFoulsString(score.AwayFouls);
            newFieldList[7].Value = getPeriodOrdinal(score.Period);
            newFieldList[8].Value = score.ShotClock;
            newFieldList[9].Value = getTargetScoreString(score.TargetScore);
            // this.setState({
            //   IsELAM: score.IsELAM,
            //   IsFinal: score.IsFinal,
            //   FieldList: newFieldList,
            // });
            setFieldList(newFieldList);
          }
        } else if (e.data["type"] == "scheduledUpdate") {
          // this.stopReceiving();

          var bugInfo = e.data["data"];
          setBackImage(bugInfo["image"]);
          // this.setState({
          //   backImage: bugInfo["image"],
          //   // receiveComputerID: bugInfo["transmitID"],
          // });

          // this.props.setReceiveID(bugInfo["transmitID"]);

          // this.startReceiving();
        }
      };
      // console.log(props.ReceiveComputerID);
      worker.postMessage({
        protocol: window.location.protocol,
        baseURI: window.location.host,
        receiveVenue: props.venue,
        receiveDelay: parseInt(props.receiveDelay),
        IsReceiving: true,
        ownId: props.ComputerID,
        connectionStatus: "init",
      });
      // this.setState({ worker: worker });
      setWorker(worker);
      props.setIsReceiving(true);
    }
  };
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const stopReceiving = () => {
    terminateWorker();
    props.setIsReceiving(false);
  };
  const terminateWorker = async () => {
    if (worker) {
      worker.postMessage({
        protocol: window.location.protocol,
        baseURI: window.location.host,
        receiveVenue: props.venue,
        receiveDelay: parseInt(props.receiveDelay),
        IsReceiving: false,
        ownId: props.ComputerID,
        connectionStatus: "stop",
      });
      await delay(500);
      worker.terminate();
      //this.setState({ worker: null });
      setWorker(null);
    }
  };

  const handleFieldChange = useCallback((element) => {
    var objIndex = FieldList.findIndex((obj) => obj.Id == element.Id);

    if (
      !isNaN(parseFloat(element.X)) &&
      !isNaN(parseFloat(element.Y)) &&
      !isNaN(parseFloat(element.Width)) &&
      !isNaN(parseFloat(element.Height))
    ) {
      biRef[objIndex].updatePosition(
        parseFloat(element.X),
        parseFloat(element.Y),
        parseFloat(element.Width),
        parseFloat(element.Height)
      );
      const updatedObject = FieldList.map((field) =>
        field.Id === element.Id ? element : field
      );
      setFieldList(updatedObject);

      setSelectedField((p) => ({
        ...element,
      }));
      //console.log("set selected field handleFieldChange " + element.Y);
      setRefreshUI(!refreshUI);
    }
  });

  function handleFieldPositionChange(element) {
    var objIndex = FieldList.findIndex((obj) => obj.Id == element.Id);

    const updatedObject = FieldList.map((field) =>
      field.Id === element.Id ? element : field
    );

    setFieldList(updatedObject);

    setSelectedField((p) => ({
      ...element,
    }));

    // console.log("set selected field positionChange" + element.Y);
  }
  function handleSizeChange(element) {
    var objIndex = FieldList.findIndex((obj) => obj.Id == element.Id);

    const updatedObject = FieldList.map((field) =>
      field.Id === element.Id ? element : field
    );

    setFieldList(updatedObject);

    setSelectedField((p) => ({
      ...element,
    }));

    // console.log("set selected field handleSizeChange" + element.Y);
  }

  const getSaveData = () => {
    if (props.IsMasterTemplate) {
      return JSON.stringify({
        color: backColor,
        template: FieldList,
      });
    } else {
      return JSON.stringify({
        color: backColor,
        image: backImage,
        transmitID: props.ReceiveComputerID,
        template: FieldList,
      });
    }
  };
  const saveFieldData = () => {
    var url = "/api/setScorebugLayout/?id=" + props.ComputerID;
    if (props.IsMasterTemplate) {
      url = "/api/setMasterLayout/?lang=" + props.selectedLanguage;
    }
    fetch(url, {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: getSaveData(),
    })
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        console.log(jsonData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var biRef = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
  return (
    <div
      id="mainPanel"
      style={{
        backgroundImage: "url(data:image/png;base64," + backImage + ")",
        backgroundPosition: "center",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        backgroundColor: backColor,

        margin: "0px",
        borderRadius: "30px",
        backgroundColor: "#0c1428",
      }}
    >
      <Stack alignItems="flex-end" sx={{ padding: "1rem" }}>
        <IconButton
          onClick={() => {
            if (!document.fullscreenElement) {
              var mainPanel = document.querySelector("#mainPanel");
              mainPanel.requestFullscreen();
            } else {
              document.exitFullscreen();
            }
            //this.setState({ sizeChanged: !this.state.sizeChanged });
          }}
          style={{ color: "white" }}
        >
          <CloseFullscreenIcon />
        </IconButton>
      </Stack>

      <TemplateManger
        Color={backColor}
        Scorebug={backImage}
        FieldList={FieldList}
        setBackColor={(color) => setBackColor(color)}
        setBackImage={(img) => setBackImage(img)}
        setFieldList={(fieldList) => setFieldList(fieldList)}
      />

      <div
        style={{
          right: "1rem",
          top: "200px",
          position: "absolute",
          zIndex: "2",
        }}
      >
        <Stack direction="column" alignItems="end">
          <Stack direction="row" alignItems="center">
            <TextField
              value={backColor}
              onChange={(event) => {
                setBackColor(event.target.value);
              }}
              variant="standard"
              label="Color"
              InputProps={{ sx: { color: "white" } }}
              InputLabelProps={{ sx: { color: "white" } }}
            />
            <IconButton
              color="primary"
              onClick={() => setShowClrPicker(!showClrPicker)}
            >
              <ColorizeOutlinedIcon style={{ width: "30px", height: "30px" }} />
            </IconButton>

            {showClrPicker && (
              <div
                style={{
                  right: "50px",
                  top: "60px",
                  position: "absolute",
                  zIndex: "2",
                }}
              >
                <div
                  style={{
                    top: "0px",
                    bottom: "0px",
                    left: "0px",
                    right: "0px",
                    position: "fixed",
                  }}
                  onClick={(_) => setShowClrPicker(false)}
                />
                <SketchPicker
                  color={backColor}
                  onChange={(color) => {
                    setBackColor(color.hex);
                  }}
                />
              </div>
            )}
            <label htmlFor="contained-button-file">
              <Input
                accept="image/*"
                id="contained-button-file"
                type="file"
                onChange={(event) => handleImage(event)}
              />
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
              >
                <WallpaperIcon />
              </IconButton>
            </label>
          </Stack>
        </Stack>
      </div>
      {selectedField && (
        <ElementProp
          data={selectedField}
          handleFieldChange={handleFieldChange}
          saveFieldData={saveFieldData}
        />
      )}
      {
        <ul
          style={{
            listStyle: "none",
          }}
        >
          {FieldList.map((field, i) => (
            <li key={field.Id} onClick={handleSetField(i)}>
              <DragElement
                data={field}
                handlePositionChange={handleFieldPositionChange}
                handleSizeChange={handleSizeChange}
                biRef={biRef[i]}
              />
            </li>
          ))}
        </ul>
      }
    </div>
  );
}

export default Receive_Edit;
