import {
  Button,
  Card,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import "..//Manual//Manual.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import React, { useEffect, useState } from "react";
import localizedStrings from "../../localization/localizedStrings";
import SERVER from "./Constants";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Cookies from "js-cookie";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { CircularProgressbar } from "react-circular-progressbar";
const ReactDomServer = require("react-dom/server");

class Board extends React.Component {
  constructor(props) {
    super(props);
    localizedStrings.setLanguage(props.selectedLanguage);
    this.state = {
      intervalId: {},
      mainClock: false,
      mainClockKey: false,
      shotClock: false,
      shotClockKey: false,
      mainClockProgressValue: 0,
    };
    this.interval = 200;
    // this.serverIP = "localhost:3000"; //"94.176.235.224:3000"; //"localhost:3000"; //"94.176.235.224:3000";
    // this.scoreObj = props.score;
    this.isTransmitting = false;
    this.isConnected = false;
    this.isUnmounted = false;
    console.log(props.score);
  }
  delay = (ms) => new Promise((res) => setTimeout(res, ms));
  getPeriodOrdinal = (period) => {
    if (period == "1") return localizedStrings._1st;
    else if (period == "2") return localizedStrings._2nd;
    else if (period == "3") return localizedStrings._3rd;
    else if (period == "4") return localizedStrings._4th;
  };
  componentDidMount() {
    var ID = Cookies.get("ComputerID");
    this.props.biRef.startTransmitting = this.startTransmitting;
    this.props.biRef.stopTransmitting = this.stopTransmitting;

    if (ID) this.createConnection(ID);
  }
  createConnection = (Id) => {
    this.socketInstance = new W3CWebSocket(SERVER.url);
    this.socketInstance.onopen = () => {
      console.log("[open] Connection established ");
      var venue = Cookies.get("VenueID");
      this.isConnected = true;
      this.props.setIsConnected(true);
      this.socketInstance.send(
        JSON.stringify({
          type: "ID",
          data: { venue: venue },
          id: Id,
        })
      );
      this.startTransmitting();
      this.props.setIsTransmitting(true);
    };

    this.socketInstance.onmessage = (ev) => {
      var message = JSON.parse(ev.data);
      if (message["type"] == "action") {
        console.log(message);
        if (message["data"] == "start" && !this.isTransmitting) {
          this.startTransmitting();
          this.props.setIsTransmitting(true);
        } else if (message["data"] == "stop" && this.isTransmitting) {
          this.stopTransmitting();
          this.props.setIsTransmitting(false);
        } else if (message["data"] == "config") {
          this.props.setConfig(message["config"]);
        }
      }
    };
    this.socketInstance.onclose = (ev) => {
      this.props.setIsConnected(false);
      // this.stopTransmitting();
      this.isConnected = false;
      this.props.setIsTransmitting(false);
      console.log("socket closed");
      if (!this.isUnmounted)
        setTimeout(() => {
          console.log("reconnecting");
          var ID = Cookies.get("ComputerID");
          this.createConnection(ID);
        }, 5000);
    };

    this.socketInstance.onerror = (error) => {
      // this.stopTransmitting();
      this.isConnected = false;
      this.props.setIsTransmitting(false);
      console.log("socket error");
      // this.socketInstance.close();
    };
  };

  transmitData = () => {
    var venue = Cookies.get("VenueID");
    if (this.socketInstance && this.socketInstance.readyState == 1)
      this.socketInstance.send(
        JSON.stringify({
          type: "score",
          data: this.props.score.getData(),
          id: this.props.ComputerID,
          venue: venue,
        })
      );

    if (this.isTransmitting) {
      var interval = this.interval;
      if (this.props.score.Time.includes(".")) interval = 10;
      this.delay(interval).then(() => this.transmitData());
    }
  };
  componentWillUnmount() {
    if (this.socketInstance && this.socketInstance.readyState == 1) {
      this.isUnmounted = true;
      this.socketInstance.close();
      console.log("socket disconnected");
    }
    this.isTransmitting = false;
    this.isConnected = false;
  }

  startTransmitting = () => {
    var ID = Cookies.get("ComputerID");
    this.socketInstance.send(
      JSON.stringify({
        type: "status",
        data: { IsTransmitting: true, IsReceiving: false },
        id: ID,
      })
    );

    this.isTransmitting = true;
    this.transmitData();
  };
  stopTransmitting = () => {
    var ID = Cookies.get("ComputerID");
    // clearInterval(this.state.intervalId);
    this.socketInstance.send(
      JSON.stringify({
        type: "status",
        data: { IsTransmitting: false, IsReceiving: false },
        id: ID,
      })
    );
    this.isTransmitting = false;
  };
  renderShotTime = ({ remainingTime }) => {
    this.shottime = remainingTime;

    return (
      <Typography
        sx={{
          color: "white",
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {remainingTime}
      </Typography>
    );
  };
  render() {
    return (
      <div className="manualGrd">
        <Stack
          className="scoreBoard"
          direction="row"
          spacing={1}
          style={{ justifyContent: "space-evenly" }}
        >
          <Stack className="manualGrid" spacing={4}>
            <div style={{ marginTop: "2rem" }}>
              {(!this.props.score.IsELAM || this.props.score.IsFinal) && (
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      paddingLeft: "1rem",
                    }}
                  >
                    Game Clock
                  </Typography>
                  <div
                    className="horizontalPanel"
                    style={{
                      margin: "1rem 0 0.5rem 0",
                      height: 170,
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{ width: 200, height: 200, position: "absolute" }}
                    >
                      <CircularProgressbar
                        maxValue={1}
                        value={this.state.mainClockProgressValue}
                        strokeWidth={4}
                        styles={{
                          root: {},
                          path: {
                            stroke: `rgba(62, 152, 199, ${
                              this.state.mainClockProgressValue * 255
                            })`,
                            strokeLinecap: "butt",
                            transition: "stroke-dashoffset 0.5s ease 0s",
                          },
                          trail: {
                            stroke: "#d6d6d6",
                            strokeLinecap: "butt",
                          },
                        }}
                      />
                    </div>

                    <TextField
                      variant="standard"
                      value={this.props.score.Time}
                      onChange={(e) => {
                        this.scoreObj.Time = e.target.value;
                        this.setState({ score: this.scoreObj });
                      }}
                      sx={{
                        input: {
                          color: "white",
                          fontSize: "55px",
                          width: "140px",
                          fontWeight: "bold",
                          textAlign: "center",
                          borderColor: "red",
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              {this.props.score.IsELAM && !this.props.score.IsFinal && (
                <div>
                  <Typography
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      paddingLeft: "1rem",
                    }}
                  >
                    {localizedStrings.tgt}
                  </Typography>

                  <div className="horizontalPanel">
                    <TextField
                      variant="standard"
                      value={this.props.score.TargetScore}
                      sx={{
                        input: {
                          color: "white",
                          fontSize: "55px",
                          width: "225px",
                          fontWeight: "bold",
                          textAlign: "center",
                        },
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
            <Stack
              direction="row"
              sx={{ marginTop: "1rem" }}
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="h5"
                sx={{ color: "white", fontWeight: "bold" }}
              >
                PERIOD
              </Typography>
              {/* <FormControl variant="standard" sx={{ m: 1, width: 100 }}>
                <Select
                  value={this.props.score.Period}
                  onChange={(e) => {
                    this.props.score.Period = e.target.value;
                    this.setState({ score: this.props.score });
                  }}
                  sx={{ fontSize: "30px", fontWeight: "bold" }}
                >
                  {periodList.map((period, i) => (
                    <MenuItem key={i} value={period}>
                      {getPeriodOrdinal(period)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              <TextField
                variant="standard"
                value={this.getPeriodOrdinal(this.props.score.Period)}
                sx={{
                  input: {
                    color: "white",
                    fontSize: "32px",
                    width: "100px",
                    fontWeight: "bold",
                    textAlign: "center",
                  },
                }}
              />
            </Stack>

            <div>
              <Typography
                sx={{ color: "white", fontWeight: "bold", paddingLeft: "1rem" }}
              >
                Shot Clock
              </Typography>
              <div style={{ marginTop: "2rem" }} className="horizontalPanel">
                <CountdownCircleTimer
                  isPlaying={false}
                  duration={
                    this.props.score.ShotClock
                      ? parseInt(this.props.score.ShotClock)
                      : 0
                  }
                  colors={[
                    "#22ed0c",
                    "#004777",
                    "#A36F00",
                    "#A30000",
                    "#A30000",
                  ]}
                  colorsTime={[12, 10, 7, 5, 0]}
                  strokeWidth={6}
                  size={138}
                  style={{
                    fontSize: "33px",
                    color: "#ffffff",
                    fontWeight: 700,
                    fontFamily: "Poppins",
                  }}
                >
                  {this.renderShotTime}
                </CountdownCircleTimer>
                {/* <TextField
                  variant="standard"
                  InputProps={{ readOnly: true }}
                  value={this.props.score.ShotClock}
                  onChange={(e) => {
                    this.props.score.ShotClock = e.target.value;
                    this.setState({ score: this.props.score });
                  }}
                  sx={{
                    input: {
                      color: "white",
                      fontSize: "45px",
                      width: "75px",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                  }}
                /> */}
              </div>
            </div>
          </Stack>
          <Stack className="manualGrid" spacing={5}>
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                paddingLeft: "1rem",
                marginTop: "2rem",
              }}
            >
              Home
            </Typography>
            <div className="horizontalPanel">
              <TextField
                value={this.props.score.HomeScore}
                onChange={(e) => {
                  this.props.score.HomeScore = e.target.value;
                  this.setState({ score: this.props.score });
                }}
                InputProps={{ readOnly: true }}
                variant="standard"
                className="home_score"
                sx={{
                  input: {
                    color: "white",
                    fontSize: "54px",
                    width: "75px",
                    fontWeight: "bold",
                    textAlign: "center",
                  },
                }}
              />
            </div>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Stack spacing={4} alignItems="center">
                <Typography sx={{ color: "white", fontWeight: "bold" }}>
                  TOLS
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    value={this.props.score.HomeTOL}
                    InputProps={{ readOnly: true }}
                    onChange={(e) => {
                      this.props.score.HomeTOL = e.target.value;
                      this.setState({ score: this.props.score });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={4} alignItems="center">
                <Typography sx={{ color: "white", fontWeight: "bold" }}>
                  {localizedStrings.fouls}
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    value={this.props.score.HomeFouls}
                    onChange={(e) => {
                      this.props.score.HomeFouls = e.target.value;
                      this.setState({ score: this.props.score });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack className="manualGrid" spacing={5}>
            <Typography
              sx={{
                color: "white",
                fontWeight: "bold",
                paddingLeft: "1rem",
                marginTop: "2rem",
              }}
            >
              Away
            </Typography>
            <div className="horizontalPanel">
              <TextField
                variant="standard"
                InputProps={{ readOnly: true }}
                value={this.props.score.AwayScore}
                onChange={(e) => {
                  this.props.score.AwayScore = e.target.value;
                  this.setState({ score: this.props.score });
                }}
                className="home_score"
                sx={{
                  input: {
                    color: "white",
                    fontSize: "54px",
                    width: "75px",
                    fontWeight: "bold",
                    textAlign: "center",
                  },
                }}
              />
            </div>
            <Stack
              direction="row"
              spacing={4}
              alignItems="center"
              justifyContent="center"
            >
              <Stack spacing={4} alignItems="center">
                <Typography sx={{ color: "white", fontWeight: "bold" }}>
                  TOLS
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    value={this.props.score.AwayTOL}
                    onChange={(e) => {
                      this.props.score.AwayTOL = e.target.value;
                      this.setState({ score: this.props.score });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
              </Stack>
              <Stack spacing={4} alignItems="center">
                <Typography sx={{ color: "white", fontWeight: "bold" }}>
                  {localizedStrings.fouls}
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  className="horizontalPanel tols"
                >
                  <TextField
                    variant="standard"
                    InputProps={{ readOnly: true }}
                    value={this.props.score.AwayFouls}
                    onChange={(e) => {
                      this.props.score.AwayFouls = e.target.value;
                      this.setState({ score: this.props.score });
                    }}
                    sx={{
                      input: {
                        color: "white",
                        fontSize: "30px",
                        width: "50px",
                        fontWeight: "bold",
                        textAlign: "center",
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  }
}

export default Board;
