import React, { useEffect, useRef, useState } from "react";
import "./Receive.css";
import localizedStrings from "../../localization/localizedStrings";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { IconButton, Stack } from "@mui/material";
import Cookies from "js-cookie";

function Element(props) {
  const inputEl = useRef(null);
  useEffect(() => {
    const elmnt = inputEl.current;
    elmnt.style.left = props.data.X * window.screen.width + "px";
    elmnt.style.top = props.data.Y * window.screen.height + "px";
    if (!(props.data.Name.includes("TOL") && props.isLineTOL)) {
      elmnt.style.width = props.data.Width * window.screen.width + "px";
      elmnt.style.height = props.data.Height * window.screen.height + "px";
    }
  }, [props]);

  function getTextAlign() {
    if (props.data.TextAlign == 1) return "left";
    else if (props.data.TextAlign == 2) return "right";
    else if (props.data.TextAlign == 3) return "center";
    else if (props.data.TextAlign == 4) return "justify";
    else return "";
  }
  function getColor() {
    if (props.data.Name.includes("Fouls")) {
      if (!props.data.Value) return props.data.Color;
      if (props.data.Value.toLowerCase().includes("fouls")) {
        return props.data.Color;
      } else return props.penaltyColor;
    } else return props.data.Color;

    // props.data.Name.includes("Fouls") && props.data.Value
    //               ? props.data.Value.toLowerCase().includes("fouls")
    //                 ? props.data.Color
    //                 : props.penaltyColor
    //               : "#ffffff",
  }

  if (props.data.Name.includes("TOL") && props.isLineTOL) {
    var tol = parseInt(props.data.Value);
    if (!isNaN(tol))
      return (
        <div
          className="draggable-parent"
          style={{ gap: "5px", overflow: "hidden", border: "0px white" }}
          ref={inputEl}
        >
          {[...Array(tol).keys()].map((i) => (
            <div
              key={i}
              style={{
                backgroundColor: "white",
                borderRadius: "15px",
                width: props.data.Width * window.screen.width + "px",
                height: props.data.Height * window.screen.height + "px",
                overflow: "hidden",
              }}
            ></div>
          ))}
        </div>
      );
    else return <div ref={inputEl}></div>;
  } else if (props.data.Id == 10 && (!props.IsELAM || props.IsFinal)) {
    return <div ref={inputEl}></div>;
  } else if (props.data.Id == 1 && props.IsELAM && !props.IsFinal) {
    return <div ref={inputEl}></div>;
  } else {
    return (
      <div
        ref={inputEl}
        style={{
          borderColor: "transparent",
          backgroundColor: props.data.BackgroundColor,
          overflow: "hidden",
        }}
        className="draggable-parent"
      >
        <div
          style={{
            textAlign: getTextAlign(),
            alignSelf: "center",
            flex: "1",
            overflow: "hidden",
          }}
          className="draggable"
        >
          <span
            style={{
              fontSize: props.data.FontSize,
              color: getColor(),
              fontWeight: props.data.Bold && "bold",
              fontFamily: props.data.FontFamily,
            }}
          >
            {props.data.Value}
          </span>
        </div>
      </div>
    );
  }
}

class Receive extends React.Component {
  constructor(props) {
    super(props);
    localizedStrings.setLanguage(props.selectedLanguage);

    this.state = {
      FieldList: [],
      backColor: "black",
      backImage: "",
      receiveComputerID: props.ReceiveComputerID,
      IsELAM: false,
      IsFinal: false,
      worker: null,
    };
    console.log("rcievedn=>props", props);
    props.biRef.startReceiving = this.startReceiving;
    props.biRef.stopReceiving = this.stopReceiving;
  }
  getPeriodOrdinal = (period) => {
    //console.log(localizedStrings);
    if (period == "1")
      return this.props.isUppercasePeriod
        ? localizedStrings._1ST
        : localizedStrings._1st;
    else if (period == "2")
      return this.props.isUppercasePeriod
        ? localizedStrings._2ND
        : localizedStrings._2nd;
    else if (period == "3")
      return this.props.isUppercasePeriod
        ? localizedStrings._3RD
        : localizedStrings._3rd;
    else if (period == "4")
      return this.props.isUppercasePeriod
        ? localizedStrings._4TH
        : localizedStrings._4th;
  };
  getFoulsString = (fouls) => {
    //console.log(localizedStrings);
    if (
      !isNaN(parseInt(fouls)) &&
      !isNaN(parseInt(this.props.config.noOfFoulsPerPenalty))
    ) {
      if (parseInt(fouls) >= parseInt(this.props.config.noOfFoulsPerPenalty)) {
        if (this.props.config.penaltyWord == "bonus")
          return localizedStrings.bonus;
        else return localizedStrings.penalty;
      } else {
        if (this.props.config.showFoulsText)
          return localizedStrings.fouls + ": " + fouls;
        else return fouls;
      }
    } else return "";
  };
  getTargetScoreString = (targetScore) => {
    if (!isNaN(parseInt(targetScore))) {
      return localizedStrings.tgt + " " + targetScore;
    } else return "";
  };
  initialFetch = () => {
    var venue = Cookies.get("VenueID");
    fetch(
      "/api/getScorebugLayout/?id=" +
        this.props.ComputerID +
        "&venue=" +
        venue +
        "&lang=" +
        this.props.selectedLanguage,
      {
        method: "get",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        else throw Error(response);
      })
      .then((jsonData) => {
        console.log(jsonData);
        this.setState(
          {
            FieldList: jsonData["template"],
            backColor: jsonData["color"],
            backImage: jsonData["image"],
            receiveComputerID: jsonData["transmitID"],
          },
          () => this.startReceiving()
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  delay = (ms) => new Promise((res) => setTimeout(res, ms));

  startReceiving = () => {
    const worker = new Worker(
      new URL("./Receive-Worker_ws.js", import.meta.url)
    );

    if (worker) {
      worker.onmessage = (e) => {
        // let msg = JSON.parse(e.data)
        console.log("e.data=>", e.data["data"]);
        if (e.data["type"] == "score") {
          var score = e.data["data"];
          var newFieldList = [...this.state.FieldList];
          if (this.state.FieldList.length > 0) {
            newFieldList[0].Value = score.IsFinal
              ? localizedStrings.final
              : score.Time;
            newFieldList[1].Value = score.HomeScore;
            newFieldList[2].Value = score.AwayScore;
            newFieldList[3].Value = score.HomeTOL;
            newFieldList[4].Value = score.AwayTOL;
            newFieldList[5].Value = this.getFoulsString(score.HomeFouls);
            newFieldList[6].Value = this.getFoulsString(score.AwayFouls);
            newFieldList[7].Value = this.getPeriodOrdinal(score.Period);
            newFieldList[8].Value = score.ShotClock;
            newFieldList[9].Value = this.getTargetScoreString(
              score.TargetScore
            );
            this.setState({
              IsELAM: score.IsELAM,
              IsFinal: score.IsFinal,
              FieldList: newFieldList,
            });
          }
        } else if (e.data["type"] == "scheduledUpdate") {
          // this.stopReceiving();

          var bugInfo = e.data["data"];
          this.setState({
            backImage: bugInfo["image"],
            // receiveComputerID: bugInfo["transmitID"],
          });

          // this.props.setReceiveID(bugInfo["transmitID"]);

          // this.startReceiving();
        }
      };
      console.log(this.props.ReceiveComputerID);
      worker.postMessage({
        protocol: window.location.protocol,
        baseURI: window.location.host,
        receiveVenue: this.props.config.Venue,
        receiveDelay: parseInt(this.props.receiveDelay),
        IsReceiving: true,
        ownId: this.props.ComputerID,
        connectionStatus: "init",
      });
      this.setState({ worker: worker });
      this.props.setIsReceiving(true);
    }
  };

  stopReceiving = () => {
    this.terminateWorker();
    this.props.setIsReceiving(false);
  };
  terminateWorker = async () => {
    if (this.state.worker) {
      this.state.worker.postMessage({
        protocol: window.location.protocol,
        baseURI: window.location.host,
        receiveVenue: this.props.config.Venue,
        receiveDelay: parseInt(this.props.receiveDelay),
        IsReceiving: false,
        ownId: this.props.ComputerID,
        connectionStatus: "stop",
      });
      await this.delay(500);
      this.state.worker.terminate();
      this.setState({ worker: null });
    }
  };

  componentDidMount() {
    this.initialFetch();
  }

  componentWillUnmount() {
    this.stopReceiving();
  }

  render() {
    return (
      <ul
        id="mainPanel"
        style={{
          listStyle: "none",
          backgroundImage:
            "url(data:image/png;base64," + this.state.backImage + ")",
          backgroundPosition: "center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          backgroundColor: this.state.backColor,
          margin: "0px 24px 0px 0px",
          width: "97%",
          borderRadius: "30px",
          backgroundColor: "#0c1428",
        }}
      >
        <Stack alignItems="flex-end" sx={{ padding: "1rem" }}>
          <IconButton
            onClick={() => {
              if (!document.fullscreenElement) {
                var mainPanel = document.querySelector("#mainPanel");
                mainPanel.requestFullscreen();
              } else {
                document.exitFullscreen();
              }
              //this.setState({ sizeChanged: !this.state.sizeChanged });
            }}
            style={{ color: "white" }}
          >
            <CloseFullscreenIcon />
          </IconButton>
        </Stack>
        {this.state.FieldList.map(
          (field, i) =>
            field.FieldON && (
              <li key={field.Id}>
                <Element
                  data={field}
                  IsELAM={this.state.IsELAM}
                  IsFinal={this.state.IsFinal}
                  penaltyColor={this.props.config.penaltyColor}
                  isLineTOL={this.props.config.isLineTOL}
                  showFoulsText={this.props.config.showFoulsText}
                />
              </li>
            )
        )}
      </ul>
    );
  }
}

export default Receive;
