import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useParams,
} from "react-router-dom";
import MainPage from "./MainPage";
import AdminPage from "./components/Admin/Admin";
import LoginPage from "./components/Login/Login";
import Diagnostics from "./components/Diagnostics/Diagnostics";
import TargetLogin from "./components/Target_Setting/Target_Login";
import TargetSetting from "./components/Target_Setting/Target";

function App() {
  const navigation = useNavigate();
  return (
    <Routes>
      <Route path="/" element={<LoginPage navigation={navigation} />} />
      <Route path="/main" element={<MainPage />} />
      <Route path="/diagnostics" element={<Diagnostics />} />
      <Route path="/targetlogin" element={<TargetLogin />} />

      <Route path="/login" element={<LoginPage navigation={navigation} />} />

      <Route
        path="/admin"
        element={
          <RequireAuth redirectTo="/login">
            <AdminPage />
          </RequireAuth>
        }
      />
      <Route
        path="/targetsetting/:id"
        element={
          <RequireTeamAuth redirectTo="/targetlogin">
            <TargetSetting />
          </RequireTeamAuth>
        }
      />
    </Routes>
  );
}

function RequireAuth({ children, redirectTo }) {
  let isAuthenticated = sessionStorage.getItem("authenticated");

  if (isAuthenticated === "true") return children;
  else return <Navigate to={redirectTo} />;
}
function RequireTeamAuth({ children, redirectTo, state }) {
  const { id } = useParams();

  let isAuthenticated = sessionStorage.getItem("team_authenticated");

  if (isAuthenticated === "true") return children;
  else return <Navigate to={redirectTo} state={id} />;
}
export default App;
